export const NETWORK = {
  SOLANA: 'SOLANA',
  ETHEREUM: 'ETHEREUM',
  GOERLI: 'GOERLI',
  POLYGON: 'POLYGON',
  MUMBAI: 'MUMBAI',
  HARDHAT: 'HARDHAT',
  BNB_MAINNET: 'BNB_MAINNET',
  BNB_TESTNET: 'BNB_TESTNET',
} as const;
