import { useEffect } from 'react';

export interface AsyncEffectArg {
  canceled: boolean;
}

/**
 * A thin wrapper around `useEffect()` that allows you to pass an async function
 * (like `async () => { ... }`).
 *
 * Additionally, the user callback is passed a single `AsyncEffectArg` to help with
 * race conditions that can happen as part of the React lifecycle. The arg's `canceled`
 * field will be set to `true` if the currently running effect has been canceled e.g.
 * as a result of the component unmounting.
 *
 * For example, we want to prevent the following (broken) behavior:
 *
 *  1. Component starts loading data for some prop like `categoryName`
 *  2. The component's `categoryName` prop changes, so it starts loading new data
 *  3. The load from (2) finishes, rendering the data in the UI
 *  4. The load from (1) finishes, but we DON'T want to override the loaded state from (3)
 *
 * To prevent this, you should check `arg.canceled` before calling `setData()` after
 * performing async work
 */
export function useEffectAsync(callback: (arg: AsyncEffectArg) => void | Promise<void>, deps: any[]) {
  useEffect(() => {
    const arg: AsyncEffectArg = {
      canceled: false,
    };

    callback(arg);
    return () => {
      arg.canceled = true;
    };
  }, deps);
}
