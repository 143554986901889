import { Values } from '../types';

export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_MONTH = 60 * 60 * 24 * (365 / 12);
export const SECONDS_PER_DAY = 60 * 60 * 24;

export interface IUnlockCadenceOption {
  readonly label: string;
  readonly value: number;
}

export const DISTRIBUTIONS_PAGINATE_PAGE_SIZE = 15;

export const UnlockCadenceOptions: readonly IUnlockCadenceOption[] = [
  {
    label: 'every second',
    value: 1,
  },
  {
    label: 'every day',
    value: SECONDS_PER_DAY,
  },
  {
    label: 'every week',
    value: SECONDS_PER_DAY * 7,
  },
  {
    label: 'every month',
    value: SECONDS_PER_DAY * (365 / 12),
  },
  {
    label: 'every 3 months',
    value: SECONDS_PER_DAY * (365 / 4),
  },
  {
    label: 'every 6 months',
    value: SECONDS_PER_DAY * (365 / 2),
  },
  {
    label: 'every 12 months',
    value: SECONDS_PER_DAY * (365.25 / 12) * 12,
  },
] as const;

export const STATUS = {
  NOT_STARTED: 'Not Started',
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
} as const;

// Distribution Table Categories (Title)
export const DISTRIBUTION_CATEGORY = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  COMPLETED: 'Complete',
  CANCELLED: 'Cancelled',
} as const;

export type StatusValue = Values<typeof STATUS>;
export type DistributionsCategoryValue = Values<typeof DISTRIBUTION_CATEGORY>;
