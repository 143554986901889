import { atom, selector } from 'recoil';

import { useBackendAPI } from '../../data-hooks/api-provider';

const GetAPI = async () => {
  const { api } = useBackendAPI();
  return api;
};

// To avoid the invalid calls of react hook - useBackendAPI in other atom states
export const apiAtom = atom({
  key: 'api',
  default: selector({
    key: 'defaultAPI',
    get: GetAPI,
  }),
});
