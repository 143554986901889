/**
 * A react context API provider to control an SMSModal.
 * This context wraps an SMSModal in a Promise by passing it resolve and reject as props to the modal.
 * The modal calls resolve and reject appropriately as the modal state is mutated.
 *
 * #usage:
 * import { useModal } from './data-hooks';
 * const { triggerModal } = useModal();
 * const code: string | null = await triggerModal();
 *
 */
import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import SMSModal from '../components/SMSModal';
import type { SMSStatus } from './auth-api';

export type ModalPromiseGenerator = (status?: SMSStatus) => Promise<string | null>;
interface IModalAPI {
  triggerModal: ModalPromiseGenerator;
}

const stub = () => {
  throw new Error('you forgot to wrap this component in ModalProvider');
};
const defaultContext: IModalAPI = {
  triggerModal: stub,
};
const ModalContext = createContext<IModalAPI>(defaultContext);

interface ModalProviderProps {
  chain: string;
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [resolve, setResolve] = useState<(value: string) => void>();
  const [reject, setReject] = useState<(error?: unknown) => void>();
  const [status, setStatus] = useState<SMSStatus>();

  // Create and link Modal to new Promise
  const triggerModal: ModalPromiseGenerator = (newStatus?) => {
    setIsOpen(true);
    setStatus(newStatus);
    // Wrap resolve and reject so on resolve/reject, controlled state provided by the Context is
    // correctly updated
    return new Promise((resolve, reject) => {
      setResolve(() => (value: string) => {
        setIsOpen(false);
        setStatus(undefined);
        return resolve(value);
      });
      setReject(() => (error?: Error) => {
        setIsOpen(false);
        setStatus(undefined);
        return reject(error);
      });
    });
  };

  return (
    <ModalContext.Provider value={{ triggerModal }}>
      <SMSModal isOpen={isOpen} status={status} resolve={resolve} reject={reject} />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const ctx = useContext(ModalContext);
  if (!ctx) throw new Error('ModalProvider not found');
  return ctx;
};
