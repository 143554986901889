/**
 * These constants control which user roles can see which pages.
 *
 * Members can only see pages in MEMBER_PAGES, while only superadmins can see
 * SUPERADMIN_PAGES. We assume most pages can be seen by Admins and up. Hence,
 * instead of having to add every page here, we only hardcode permissions for
 * the page in the above two cases.
 */
import { Values } from '../types';

const UNAUTHED_PAGES = ['/invite', '/claim'] as const;

const MEMBER_PAGES = [] as const;

const SUPERADMIN_PAGES = ['/superadmin'] as const;

export const PAGES = {
  UNAUTHED_PAGES,
  MEMBER_PAGES,
  SUPERADMIN_PAGES,
} as const;

export type PagesValue = Values<typeof PAGES>;
