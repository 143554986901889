// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const isProduction = process.env.ENV === 'production';

Sentry.init({
  dsn: SENTRY_DSN || 'https://ec5b94b2cdbc42daaeccc2d7f4709fe3@o1258789.ingest.sentry.io/6435469',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  enabled: isProduction,
  tunnel: '/api/tunnel',
});
