import { ITokenDBSchema } from '@/lib/interfaces/token';

import { callAPI } from '../lib/callAPI';

interface IUpdateTokenReq {
  tokenId: string;
  update: ITokenDBSchema;
}

export class TokenAPI {
  private authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  async getToken(): Promise<ITokenDBSchema> {
    const response = await callAPI(this.authToken, 'GET', '/api/token');
    return response?.data.token;
  }

  async updateToken({ tokenId, update }: IUpdateTokenReq): Promise<ITokenDBSchema> {
    const response = await callAPI(this.authToken, 'POST', `/api/token/${tokenId}`, { update });
    return response?.data.token;
  }

  async createToken(token: ITokenDBSchema): Promise<ITokenDBSchema> {
    const response = await callAPI(this.authToken, 'POST', `/api/token/`, { token });
    return response?.data.token;
  }
}
