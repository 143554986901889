import Cookies from 'js-cookie';

import type { ILogDBSchema } from '../db/models/Log';
import { callAPI } from '../lib/callAPI';
import { LOG_TYPE } from '../lib/constants/logger';

interface ICreateTransactionLogReq {
  meta: {
    onChainId?: string;
    transactionHash?: string;
  };
  requestJson: Record<any, any>;
  responseJson: Record<any, any>;
  action: string;
}

export class LogAPI {
  private authToken: string;

  constructor(authToken = '') {
    // getting JWT within the API class so we can use it in non-hook allowed scenarios
    this.authToken = authToken ? authToken : Cookies.get('AUTH_JWT') || '';
  }

  async getLogs({ type, userId }: { type?: string; userId?: string }): Promise<ILogDBSchema[]> {
    let query = '';
    if (userId) query = `?userId=${userId}`;
    if (type) {
      if (query) query = `${query}&type=${type}`;
      else query = `?type=${type}`;
    }

    const url = `/api/log${query}`;
    const response = await callAPI(this.authToken, 'GET', url);
    return response?.data.logs;
  }

  async createTransactionLog({ log }: { log: ICreateTransactionLogReq }) {
    try {
      const response = await callAPI(this.authToken, 'POST', '/api/log', {
        log: {
          ...log,
          type: LOG_TYPE.TRANSACTION,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async createWithdrawalLog({ log }: { log: ICreateTransactionLogReq }) {
    try {
      const response = await callAPI('', 'POST', '/api/log/claim', {
        log: {
          ...log,
          type: LOG_TYPE.TRANSACTION,
        },
      });
      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
