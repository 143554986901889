/**
 * A react context API provider to access backend APIs.
 * The wallet context can be used directly in this module to access the `publicKey` and `signMessage` method.
 *
 * #usage:
 * import { useBackendAPI } from './data-hooks'
 * const { api } = useBackendAPI()
 * const response = api.someAPI.getResponse()
 *
 * To access any route added in `pages/api`, a corresponding API class instance needs to be added to
 * `IBackendAPI` interface, `stubAPI` and `createAPI` method.
 * Check `DistributionAPI` class in `data-hooks/distribution-api` for reference.
 */
import { useRouter } from 'next/router';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { AuthLoading } from '../components/LoadingWithLayout';
import { getAuthTokenClient } from '../lib/auth';
import { CHAIN_TYPE, ChainTypeValue } from '../lib/constants/auth';
import { PAGES } from '../lib/constants/permissions';
import { useWallet } from '../lib/onChain';
import { useEffectAsync } from '../lib/useEffectAsync';
import { AuthAPI } from './auth-api';
// mongo model API classes
import { DistributionAPI } from './distribution-api';
import { InviteAPI } from './invite-api';
import { LogAPI } from './log-api';
import { useModal } from './modal-provider';
import { StakeholderAPI } from './stakeholder-api';
import { TokenAPI } from './token-api';
import { UserAPI } from './user-api';

export interface IBackendAPIState {
  isLoading: boolean;
}

// add more properties as required
const initialState = {
  isLoading: true,
};

export interface IBackendAPI {
  distributionAPI: DistributionAPI;
  tokenAPI: TokenAPI;
  logAPI: LogAPI;
  authAPI: AuthAPI;
  userAPI: UserAPI;
  stakeholderAPI: StakeholderAPI;
  inviteAPI: InviteAPI;
}

export interface IBackendAPIProvider {
  children: ReactNode;
  chain: ChainTypeValue;
}

export interface IBackendAPIContext {
  api: IBackendAPI;
  state: IBackendAPIState;
}

const stub: any = () => {
  throw new Error('you forgot to wrap in APIProvider');
};

const stubAPI: IBackendAPI = {
  distributionAPI: stub,
  tokenAPI: stub,
  logAPI: stub,
  authAPI: stub,
  userAPI: stub,
  stakeholderAPI: stub,
  inviteAPI: stub,
};

const createAPI = (authToken = ''): IBackendAPI => {
  return {
    distributionAPI: authToken ? new DistributionAPI(authToken) : stub,
    tokenAPI: authToken ? new TokenAPI(authToken) : stub,
    logAPI: authToken ? new LogAPI(authToken) : stub,
    authAPI: new AuthAPI(),
    userAPI: authToken ? new UserAPI(authToken) : stub,
    stakeholderAPI: authToken ? new StakeholderAPI(authToken) : stub,
    inviteAPI: new InviteAPI(authToken),
  };
};

const BackendAPIContext = React.createContext<IBackendAPIContext>({
  api: stubAPI,
  state: initialState,
});

export const BackendAPIProvider = ({ chain, children }: IBackendAPIProvider) => {
  // 1. get auth token
  // 2. create api

  const [authToken, setAuthToken] = useState<string | null>(null);
  const [api, setApi] = useState<IBackendAPI>(stubAPI);
  const [state, setState] = useState<IBackendAPIState>(initialState);
  const [error, setError] = useState<boolean>(false);

  const { publicKey, signMessage, connected } = useWallet();
  const router = useRouter();
  const noAuth = PAGES.UNAUTHED_PAGES.some((page) => router.pathname.startsWith(page));

  const { triggerModal: createSMSModal } = useModal();

  const refreshAuthToken = async () => {
    if (chain === CHAIN_TYPE.SOLANA && (!publicKey || !signMessage)) return;
    if (noAuth) return;
    if (!connected && !publicKey && chain !== CHAIN_TYPE.OTHER) return;

    const authToken = await getAuthTokenClient({ publicKey, signMessage, createSMSModal, chain });
    if (!authToken) {
      console.error(new Error(`Failure getting auth token.`));
      setError(true);
      return;
    }
    setError(false);
    setAuthToken(authToken);
  };

  useEffectAsync(refreshAuthToken, [publicKey, signMessage, chain, noAuth, connected]);

  useEffect(() => {
    const setLoading = (v: boolean) => setState((s) => ({ ...s, isLoading: v }));

    if (noAuth) {
      setApi(createAPI());
      setLoading(false);
      return;
    }

    if (chain === CHAIN_TYPE.SOLANA && (!publicKey || !signMessage)) return;
    if (!authToken) return;

    setApi(createAPI(authToken));
    setLoading(false);
  }, [publicKey, signMessage, noAuth, authToken, chain]);

  const content = state.isLoading ? <AuthLoading chain={chain} error={error} /> : children;
  return <BackendAPIContext.Provider value={{ api, state }}>{content}</BackendAPIContext.Provider>;
};

export const useBackendAPI = () => {
  const ctx = useContext(BackendAPIContext);
  if (!ctx) throw new Error('ApiProvider not found');

  return ctx;
};
