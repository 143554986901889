import { AbiItem } from 'web3-utils';
import factoryV1Artifact from '../lib/airlock-eth/FactoryV1.json';
import piecewiseScheduleV1Artifact from '../lib/airlock-eth/PiecewiseScheduleV1.json';
import { getNetworkFromProvider, getWeb3Provider } from './ethereum';

// note: has to be in the same order as view function
// e.g. here: https://github.com/magna-eng/on-chain/blob/2a68aecbd62f9c756989088038e30ec73e863662/src/ethereum/contracts/PiecewiseVester/PiecewiseScheduleV1.sol#L396
// TODO: move away from strings once we have a JSON-safe format for big numbers (i.e. > 32-bit JSON limit)
interface IAirlockV1EthereumViewResult {
  id: string;
  withdrawn: string;
  allocation: string;
  fundedAmount: string;
  vestedAmount: string;
}

export const getMany = async (distributions: any[]): Promise<any[]> => {
  try {
    const token = distributions[0].token;

    const network = getNetworkFromProvider(distributions[0].provider);
    const web3 = getWeb3Provider(network as string);

    const factoryContract = new web3.eth.Contract(
      factoryV1Artifact as AbiItem[],
      process.env.NEXT_PUBLIC_ETHEREUM_FACTORY_V1_ADDRESS,
    );

    const piecewiseScheduleContractAddress = await factoryContract.methods
      .getPiecewiseVester(token?._id.toString())
      .call();

    const piecewiseScheduleContract = new web3.eth.Contract(
      piecewiseScheduleV1Artifact as AbiItem[],
      piecewiseScheduleContractAddress,
    );

    const onChainObjects = await Promise.all(
      distributions.map((d) => piecewiseScheduleContract.methods.getScheduleMetadata(d._id.toString()).call()),
    );

    const parsedOnChainObjects = onChainObjects.map((schedule) => ({
      id: schedule.id,
      withdrawn: schedule.withdrawn,
      allocation: schedule.allocation,
      fundedAmount: schedule.fundedAmount,
      vestedAmount: schedule.vestedAmount,
    }));

    return parsedOnChainObjects as IAirlockV1EthereumViewResult[];
  } catch (e) {
    console.error(e);
    return distributions.map(() => null);
  }
};
