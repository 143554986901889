import Cohere from 'cohere-js';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { PAGES } from '../lib/constants/permissions';
import { Role } from '../lib/constants/user';
import { useEffectAsync } from '../lib/useEffectAsync';
import { userAtom } from '../state/user';

/**
 * A component which enforces page permissions. Make sure this component is under
 * RecoilRoot in the component tree.
 */

const Permissions = () => {
  const router = useRouter();
  const user = useRecoilValue(userAtom);

  useEffectAsync(async () => {
    // If the current page is in PAGES.UNAUTHED_PAGES, even unauthed users can see it.
    if (PAGES.UNAUTHED_PAGES.some((page) => router.pathname.startsWith(page))) {
      return;
    }

    if (!user?.role) {
      router.push('/');
    }

    Cohere.identify(user?._id, {
      displayName: user?.address,
      email: user?.email,
    });

    // If the current user is a MEMBER, the current page must be in PAGES.MEMBER_PAGES
    if (user?.role === Role.MEMBER) {
      if (!PAGES.MEMBER_PAGES.some((page) => router.pathname.startsWith(page))) {
        router.push('/claim');
      }
    }
    // If the current page is in PAGES.SUPERADMIN_PAGES then the user must be a SUPER_ADMIN
    if (PAGES.SUPERADMIN_PAGES.some((page) => router.pathname.startsWith(page))) {
      if (user?.role !== Role.SUPER_ADMIN) {
        router.push('/');
      }
    }
  }, [router.pathname, user]);

  return <></>;
};

export default Permissions;
