import axios, { Method } from 'axios';

export const callAPI = async (authToken: string, method: Method, url: string, data?: any) => {
  try {
    return axios({
      method: method,
      url,
      headers: { Authorization: authToken },
      data,
    });
  } catch (err) {
    // TODO: show SnackBar to display error
    console.log(err);
    throw err;
  }
};
