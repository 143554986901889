export const TRANSACTION_FAILURE_TEXT =
  'Information was incorrect or the network is taking too long to respond. Please wait a couple minutes and try again.' as const;
export const LOGIN_FAILURE_TEXT =
  'There was an error verifying your account. Please ensure your user has the correct permissions and try again.' as const;
export const WAITING_WALLET_CONFIRMATION_TEXT =
  'Please select your wallet and wait for the wallet connection to finish.' as const;
export const WAITING_ONCHAIN_DATA_TEXT = 'Querying and synchronizing all on-chain data...' as const;
export const WAITING_DATA_TEXT = 'Loading data...' as const;
export const GENERIC_PAGE_ERROR_TEXT =
  'Something went wrong. Please ensure that your data is correct and refresh the page.' as const;
export const WALLET_ERROR_HINT_TEXT =
  'If you disconnected or switched your wallet, or are experiencing errors, try refreshing the page.';
