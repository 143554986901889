import { Values } from '../types';

export const VERIFICATION_MESSAGE = 'Magna Verification' as const;

export const CHAIN_TYPE = {
  ETHEREUM: 'ETHEREUM',
  SOLANA: 'SOLANA',
  OTHER: 'OTHER',
} as const;

export type ChainTypeValue = Values<typeof CHAIN_TYPE>;
