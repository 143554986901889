import { callAPI } from '../lib/callAPI';

interface ICreateInviteReq {
  distributionId: string;
}

interface IUpdateInviteReq {
  inviteId: string;
  address: string;
}

export class InviteAPI {
  private authToken: string;

  constructor(authToken = '') {
    this.authToken = authToken;
  }

  async createInvite({ distributionId }: ICreateInviteReq) {
    return await callAPI(this.authToken, 'POST', '/api/invite', { distributionId });
  }

  async updateInvite({ inviteId, address }: IUpdateInviteReq) {
    const url = `/api/invite/${inviteId}`;
    try {
      await callAPI(this.authToken, 'POST', url, { address });
      return true;
    } catch {
      return false;
    }
  }

  async disableInvite(inviteId: string) {
    const response = await callAPI(this.authToken, 'POST', `/api/invite/${inviteId}`, { enabled: false });
    if (response?.status == 200) {
      return true;
    } else if (response?.status == 500) {
      return false;
    }
  }
}
