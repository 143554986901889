import Link from 'next/link';
import React, { forwardRef } from 'react';

/**
 * The NextJS Link component does not forward props to underlying child components.
 * This breaks some Headless UI functionality. Use this Link instead in those situations.
 * Otherwise, use the NextJS Link component.
 *
 * See https://headlessui.dev/react/menu#integrating-with-next-js for more info.
 */

interface IWrappedLinkProps {
  href: string;
  className: string;
  children?: React.ReactNode;
}

const wrappedLink = (props: IWrappedLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
  const { href, children, className, ...rest } = props;
  return (
    <Link href={href}>
      <a className={className} ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  );
};

const LinkWithProps = forwardRef(wrappedLink);
export default LinkWithProps;
