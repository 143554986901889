import { callAPI } from '../lib/callAPI';

interface IBaseReq {
  chain: string;
}
export interface IGauthReq extends IBaseReq {
  email: string;
  accessToken: string;
}
export interface IWalletReq extends IBaseReq {
  publicKey: string;
  signature?: string | Uint8Array;
}
export interface I2faReq extends IBaseReq {
  publicKey: string;
  code: string;
}
export type IGetJWTReq = IGauthReq | IWalletReq | I2faReq;

export type SMSStatus = 'pending' | 'approved' | 'canceled' | 'rejected';
export type IGetJWTRes = {
  jwt?: string;
  status?: SMSStatus;
};

export class AuthAPI {
  async getJWT(req: IGetJWTReq): Promise<IGetJWTRes | null> {
    const response = await callAPI('', 'POST', '/api/auth/login', req);
    if (response?.status == 200) return response?.data;
    return null;
  }

  async verifyJWT(jwt: string) {
    try {
      await callAPI('', 'POST', '/api/auth/verify', { jwt });
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async sendSMS(publicKey: string) {
    try {
      await callAPI('', 'POST', '/api/auth/verify', { publicKey, forceSend: true });
    } catch (err) {
      console.error(err);
    }
  }
}
