import { atom, selector } from 'recoil';
import * as Intercom from '@/lib/intercom';

import { IUserDBSchema } from '@/lib/interfaces/user';

import { apiAtom } from '../api';

export const userAtom = atom<IUserDBSchema | null>({
  key: 'user',
  default: selector<IUserDBSchema | null>({
    key: 'defaultUser',
    get: async ({ get }) => {
      const api = get(apiAtom);
      if (!api.userAPI?.getCurrentUserData) return null;
      const userData = await api.userAPI.getCurrentUserData();
      return userData || null;
    },
  }),
  effects: [
    ({ onSet }) => {
      onSet((newUser) => {
        if (newUser) {
          Intercom.boot({
            app_id: process.env.INTERCOM_APP_ID,
            email: newUser.email,
            user_id: newUser.address,
            hide_default_launcher: true,
          });
        } else {
          Intercom.shutdown();
        }
      });
    },
  ],
});
