/*
    Provider-conditioned module for on-chain interactions.

    TODO: figure out better/more consistent error handling/propagating and standardize the interfaces with TypeScript.
*/
import * as borsh from '@project-serum/borsh';
import * as Sentry from '@sentry/browser';
import * as splToken from '@solana/spl-token';
import { AnchorWallet, useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, SYSVAR_CLOCK_PUBKEY } from '@solana/web3.js';
import { BN } from 'bn.js';
import { useAccount } from 'wagmi';
import Web3 from 'web3';

import { EXPLORER_TYPE, ExplorerTypeValue, EXPLORER_NAME } from '../lib/constants/explorer';
import * as airlockEth from './airlockEth';
import * as airlockEthV1 from './airlockEthV1';
import * as airlockSol from './airlockSol';
import { CHAIN_TYPE, ChainTypeValue } from './constants/auth';
import { TRANSACTION_FAILURE_TEXT } from './constants/ui';
import { Provider, ProviderValue } from './constants/user';
import { getNetworkFromProvider, isProviderEVM } from './ethereum';
import * as furoVest from './furoVest';
import { cancelZebecStream, createZebecStream, withdrawZebecStream } from './zebec';
import { NETWORK } from './constants/network';

declare global {
  interface Window {
    solana?: {
      publicKey?: PublicKey;
    };
  }
}

export const getInitialPropsChain = () => {
  return { props: { chain: process.env.NEXT_PUBLIC_CHAIN || CHAIN_TYPE.SOLANA } } as const;
};

/**
 * Wallet getter that doesn't use any hooks so it can be used in non-react component code blocks.
 * @deprecated Use the `useWallet` hook instead.
 */
export async function getWalletPublicKey(
  chain: ChainTypeValue = process.env.NEXT_PUBLIC_CHAIN as ChainTypeValue,
): Promise<string | null> {
  if (chain === CHAIN_TYPE.SOLANA) {
    return window?.solana?.publicKey?.toString() ?? null;
  }

  if (chain === CHAIN_TYPE.ETHEREUM) {
    const accounts = await window?.ethereum?.request({ method: 'eth_requestAccounts' });
    return accounts ? Web3.utils.toChecksumAddress(accounts[0]) : null;
  }

  return null;
}

export function useWallet() {
  /* eslint-disable react-hooks/rules-of-hooks */
  const chain = process.env.NEXT_PUBLIC_CHAIN;
  if (CHAIN_TYPE.SOLANA === chain) {
    const { publicKey, signMessage } = useSolanaWallet();
    return {
      publicKey: publicKey?.toString?.() ?? null,
      connected: Boolean(publicKey),
      signMessage,
    };
  } else if (CHAIN_TYPE.ETHEREUM === chain) {
    const { address, isConnected } = useAccount();
    return {
      publicKey: address ?? null,
      connected: isConnected,
      signMessage: undefined, // TODO: Add wagmi signMessage if necessary
    };
  } else {
    return { connected: false, publicKey: null, signMessage: undefined };
  }
}

export const validateWalletAddress = async (chain: ChainTypeValue, address: string): Promise<boolean> => {
  try {
    if (chain === CHAIN_TYPE.SOLANA) {
      const key = new PublicKey(address);
      return PublicKey.isOnCurve(key.toBuffer());
    }

    if (chain === CHAIN_TYPE.ETHEREUM) {
      return Web3.utils.isAddress(address);
    }

    return false;
  } catch {
    return false;
  }
};

export const validateTokenAddress = async (chain: ChainTypeValue, address: string): Promise<boolean> => {
  try {
    if (chain === CHAIN_TYPE.SOLANA) {
      const key = new PublicKey(address);
      if (!PublicKey.isOnCurve(key.toBuffer())) return false;
      const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
      const res = await splToken.getMint(connection, key);
      return res.supply > 0;
    }

    if (chain === CHAIN_TYPE.ETHEREUM) {
      return Web3.utils.isAddress(address);
    }

    return false;
  } catch {
    return false;
  }
};

export const getNativeTokenBalance = async (publicKey: string) => {
  const chain = process.env.NEXT_PUBLIC_CHAIN;
  let balance = null;
  if (chain === CHAIN_TYPE.SOLANA) {
    const key = new PublicKey(publicKey);
    const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
    balance = new BN(await connection.getBalance(key));
  } else if (chain === CHAIN_TYPE.ETHEREUM) {
    const web3 = new Web3(Web3.givenProvider);
    balance = new BN(await web3.eth.getBalance(publicKey));
  }
  return balance;
};

export const getTokenBalance = async (publicKey: string, token: string) => {
  try {
    if (process.env.NEXT_PUBLIC_CHAIN === CHAIN_TYPE.SOLANA) {
      const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
      const tokenMint = await splToken.getMint(connection, new PublicKey(token));
      const benefactor = new PublicKey(publicKey);
      const benefactorAta = await splToken.getAssociatedTokenAddress(tokenMint.address, benefactor, true);
      const balance = await connection.getTokenAccountBalance(benefactorAta);

      return balance.value.uiAmount;
    } else if (process.env.NEXT_PUBLIC_CHAIN === CHAIN_TYPE.ETHEREUM) {
      // will use ethplorer API here, but locally we can't get the balance so we set to a high number
      return '1000000000';
    }
  } catch (error) {
    console.error({ error });
    return 0.0;
  }
};

// UI only util to get explorer name, doesn't affect URL
export const getExplorerName = (provider: ProviderValue) => {
  const network = getNetworkFromProvider(provider);

  switch (network) {
    case NETWORK.ETHEREUM:
      return EXPLORER_NAME.ETHERSCAN;
    case NETWORK.BNB_MAINNET:
      return EXPLORER_NAME.BSCSCAN;
    case NETWORK.MUMBAI:
      return EXPLORER_NAME.POLYGONSCAN;
    case NETWORK.POLYGON:
      return EXPLORER_NAME.POLYGONSCAN;
    case NETWORK.BNB_TESTNET:
      return EXPLORER_NAME.BSCSCAN;
    case NETWORK.SOLANA:
      return EXPLORER_NAME.SOLANA_EXPLORER;
    default:
      return EXPLORER_NAME.ETHERSCAN;
  }
};

// client-side only function
export const getExplorerURL = (explorerType: ExplorerTypeValue, provider: ProviderValue) => {
  const network = getNetworkFromProvider(provider);

  if (process.env.NEXT_PUBLIC_CHAIN === CHAIN_TYPE.SOLANA) {
    switch (explorerType) {
      case EXPLORER_TYPE.TRANSACTION:
        return 'https://explorer.solana.com/tx';
      default:
        return 'https://explorer.solana.com/address';
    }
  } else if (process.env.NEXT_PUBLIC_CHAIN === CHAIN_TYPE.ETHEREUM) {
    let baseURL = '';
    switch (network) {
      case NETWORK.GOERLI:
        baseURL = 'https://goerli.etherscan.io';
        break;
      case NETWORK.BNB_MAINNET:
        baseURL = 'https://bscscan.com';
        break;
      case NETWORK.BNB_TESTNET:
        baseURL = 'https://testnet.bscscan.com';
        break;
      case NETWORK.MUMBAI:
        baseURL = 'https://mumbai.polygonscan.com';
        break;
      case NETWORK.POLYGON:
        baseURL = 'https://polygonscan.com';
        break;
      default:
        baseURL = 'https://etherscan.io';
    }

    switch (explorerType) {
      case EXPLORER_TYPE.TRANSACTION:
        return `${baseURL}/tx/`;
      case EXPLORER_TYPE.ADDRESS:
        return `${baseURL}/address/`;
      case EXPLORER_TYPE.TOKEN:
        return `${baseURL}/token/`;
    }
  } else {
    throw Error('Unknown chain type: ' + process.env.NEXT_PUBLIC_CHAIN);
  }
};

export const bulkCreate = async (
  wallet: AnchorWallet,
  provider: ProviderValue,
  distributions: any[],
  senderAddress: string,
) => {
  if (provider === Provider.AIRLOCK_SOL) {
    const { transactionHashes, onChainIds, error } = await airlockSol.bulkCreate(wallet, distributions, senderAddress);
    return { transactionHashes, onChainIds, error };
  }
};

export const bulkCancel = async (signer: AnchorWallet, provider: ProviderValue, distributions: any[]) => {
  if (provider === Provider.AIRLOCK_SOL) {
    const { transactionHashes, onChainIds, error } = await airlockSol.bulkCancel(signer, distributions);
    return { transactionHashes, onChainIds, error };
  }

  return { transactionHashes: [], onChainIds: [], error: 'Unknown provider.' };
};

export const createStream = async (
  wallet: AnchorWallet,
  provider: ProviderValue,
  distribution: any,
  senderAddress: string,
) => {
  try {
    if (provider === Provider.ZEBEC) {
      const response = await createZebecStream(
        wallet,
        distribution.owner.address,
        distribution.stakeholder.address,
        distribution.allocation,
        distribution.startTime,
        distribution.endTime,
        distribution.tokenAddress,
      );

      return {
        status: response.status,
        message: response.message,
        onChainId: response.data?.pda,
        transactionHash: response.data?.transactionhash,
      };
    } else if (provider === Provider.AIRLOCK_ETH) {
      const { transactionHash, onChainId } = await airlockEth.create(distribution, senderAddress);
      return { transactionHash, onChainId };
    } else if (provider === Provider.AIRLOCK_BNB_MAINNET_V0 || provider === Provider.AIRLOCK_BNB_TESTNET_V0) {
      const { transactionHash, onChainId } = await airlockEth.create(distribution, senderAddress);
      return { transactionHash, onChainId };
    } else if (provider === Provider.AIRLOCK_POLYGON_V0 || provider === Provider.AIRLOCK_MUMBAI_V0) {
      const { transactionHash, onChainId } = await airlockEth.create(distribution, senderAddress);
      return { transactionHash, onChainId };
    } else if (provider === Provider.AIRLOCK_SOL) {
      const { transactionHash, onChainId, error } = await airlockSol.create(wallet, distribution, senderAddress);
      return { transactionHash, onChainId, error };
    } else if (provider === Provider.FURO) {
      const create = await furoVest.createVesting(distribution);
      return { transactionHash: create!.transactionHash, onChainId: create!.onChainId };
    } else {
      throw Error('Unknown provider: ' + provider);
    }
  } catch (exception) {
    Sentry.captureException(exception);
    console.log(exception);

    return {
      error: exception,
      message: TRANSACTION_FAILURE_TEXT,
    };
  }
};

type cancelStreamParams = {
  _id: string;
  sender: string;
  recipientAddress: string;
  onChainId: string;
  tokenAddress: string;
  provider: ProviderValue;
};
export const cancelStream = async (wallet: AnchorWallet, provider: ProviderValue, params: cancelStreamParams) => {
  try {
    if (provider === Provider.ZEBEC) {
      const response = await cancelZebecStream(
        wallet,
        params.sender,
        params.recipientAddress,
        params.onChainId,
        params.tokenAddress,
      );

      return {
        status: response.status,
        message: response.message,
        onChainId: response.data?.pda,
        transactionHash: response.data?.transactionhash,
      };
    } else if (provider === Provider.AIRLOCK_SOL) {
      const { transactionHash, error } = await airlockSol.cancel(wallet, params);
      return { transactionHash, error };
    } else if (provider === Provider.AIRLOCK_ETH) {
      const { transactionHash } = await airlockEth.cancel(params);
      return { transactionHash };
    } else if (provider === Provider.AIRLOCK_BNB_MAINNET_V0 || provider === Provider.AIRLOCK_BNB_TESTNET_V0) {
      const { transactionHash } = await airlockEth.cancel(params);
      return { transactionHash };
    } else if (provider === Provider.AIRLOCK_POLYGON_V0 || provider === Provider.AIRLOCK_MUMBAI_V0) {
      const { transactionHash } = await airlockEth.cancel(params);
      return { transactionHash };
    } else if (provider === Provider.FURO) {
      const { transactionHash } = await furoVest.cancelVesting(params.onChainId, params.sender);
      return { transactionHash };
    } else {
      throw Error('Unknown provider: ' + provider);
    }
  } catch (exception) {
    Sentry.captureException(exception);
    console.log(exception);
    return {
      status: 'error',
      error: exception,
      message: TRANSACTION_FAILURE_TEXT,
    };
  }
};

export const withdrawStream = async (wallet: AnchorWallet | undefined, distribution: any, amountToWithdraw: number) => {
  try {
    if (distribution.provider === Provider.ZEBEC && wallet) {
      const response = await withdrawZebecStream(
        wallet,
        distribution.sender,
        distribution.stakeholder.recipientAddress,
        distribution.pieces[distribution.pieces.length - 1].onChainId,
        parseFloat(amountToWithdraw.toFixed(2)),
        distribution.token.address,
      );

      return {
        status: response.status,
        message: response.message,
        onChainId: response.data?.pda,
        transactionHash: response.data?.transactionhash,
      };
    } else if (distribution.provider === Provider.AIRLOCK_SOL && wallet) {
      const { transactionHash, error } = await airlockSol.withdraw(wallet, distribution);
      return { transactionHash, error };
    } else if (distribution.provider === Provider.AIRLOCK_ETH) {
      const { transactionHash } = await airlockEth.withdraw(distribution, distribution.stakeholder.address);
      return { transactionHash };
    } else if (
      distribution.provider === Provider.AIRLOCK_BNB_MAINNET_V0 ||
      distribution.provider === Provider.AIRLOCK_BNB_TESTNET_V0
    ) {
      const { transactionHash } = await airlockEth.withdraw(distribution, distribution.stakeholder.address);
      return { transactionHash };
    } else if (
      distribution.provider === Provider.AIRLOCK_POLYGON_V0 ||
      distribution.provider === Provider.AIRLOCK_MUMBAI_V0
    ) {
      const { transactionHash } = await airlockEth.withdraw(distribution, distribution.stakeholder.address);
      return { transactionHash };
    } else if (distribution.provider === Provider.FURO) {
      const { transactionHash } = await furoVest.withdrawVesting(
        distribution.pieces[distribution.pieces.length - 1].onChainId,
        distribution.stakeholder.address,
      );
      return { transactionHash };
    } else {
      throw Error('Unknown provider: ' + distribution.provider);
    }
  } catch (exception) {
    Sentry.captureException(exception);
    console.log(exception);

    return {
      status: 'error',
      error: exception,
      message: TRANSACTION_FAILURE_TEXT,
    };
  }
};

export const getSolanaClockTimestamp = async () => {
  const SYSVAR_CLOCK_LAYOUT = borsh.struct([
    borsh.u64('slot'),
    borsh.u64('epoch_start_timestamp'),
    borsh.u64('epoch'),
    borsh.u64('leader_schedule_epoch'),
    borsh.u64('unix_timestamp'),
  ]);

  const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
  const clockAccount = await connection.getAccountInfo(SYSVAR_CLOCK_PUBKEY);
  const clockState = SYSVAR_CLOCK_LAYOUT.decode(clockAccount?.data);
  return parseInt(clockState.unix_timestamp);
};

export const getOnChainId = async (distribution: any) => {
  if (distribution.provider === Provider.AIRLOCK_SOL) {
    return await airlockSol.getOnChainId(distribution);
  } else if (distribution.provider === Provider.AIRLOCK_ETH) {
    return await airlockEth.getOnChainId(distribution);
  } else if (
    distribution.provider === Provider.AIRLOCK_BNB_MAINNET_V0 ||
    distribution.provider === Provider.AIRLOCK_BNB_TESTNET_V0
  ) {
    return await airlockEth.getOnChainId(distribution);
  } else if (
    distribution.provider === Provider.AIRLOCK_POLYGON_V0 ||
    distribution.provider === Provider.AIRLOCK_MUMBAI_V0
  ) {
    return await airlockEth.getOnChainId(distribution);
  } else {
    throw Error('Unsupported provider: ' + distribution.provider);
  }
};

export const getOnChainObjects = async (distributions: any[]) => {
  if (distributions[0]?.provider === Provider.AIRLOCK_SOL) {
    const onChainObjects = await airlockSol.getMany(distributions);
    return onChainObjects;
  } else if (distributions[0]?.provider === Provider.AIRLOCK_ETH) {
    const onChainObjects = await airlockEth.getMany(distributions);
    return onChainObjects;
  } else if (
    distributions[0]?.provider === Provider.AIRLOCK_BNB_MAINNET_V0 ||
    distributions[0]?.provider === Provider.AIRLOCK_BNB_TESTNET_V0
  ) {
    const onChainObjects = await airlockEth.getMany(distributions);
    return onChainObjects;
  } else if (
    distributions[0]?.provider === Provider.AIRLOCK_MUMBAI_V0 ||
    distributions[0]?.provider === Provider.AIRLOCK_POLYGON_V0
  ) {
    const onChainObjects = await airlockEth.getMany(distributions);
    return onChainObjects;
  } else if (isProviderEVM(distributions[0]?.provider)) {
    const onChainObjects = await airlockEthV1.getMany(distributions);
    return onChainObjects;
  }

  return [];
};
