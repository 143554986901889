import { Disclosure, Menu } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { logoutWallet } from '@/lib/auth';
import { useWallet } from '@/lib/onChain';

import { CHAIN_TYPE } from '../lib/constants/auth';
import { Role } from '../lib/constants/user';
import { userAtom } from '../state/user';
import LinkWithProps from './LinkWithProps';
import WalletSelectButton from './WalletSelectButton';

interface INavbarElement {
  name: string;
  href: string;
  current: boolean;
}

interface ILayoutProps {
  chain: string;
  children?: ReactNode;
}

interface IBaseLayoutProps extends ILayoutProps {
  navigation?: INavbarElement[];
}

export function BaseLayout({ children, chain, navigation = [] }: IBaseLayoutProps) {
  return (
    <>
      <div className="min-h-screen bg-black pt-4">
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  {navigation.length > 0 && (
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button
                        className="inline-flex items-center justify-center rounded-md
                        p-2 text-zinc-400 hover:bg-zinc-700 hover:text-white focus:outline-none
                        focus:ring-2 focus:ring-inset focus:ring-white"
                      >
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  )}
                  <div className="flex flex-1 items-stretch justify-start">
                    <div className="mt-2 flex flex-shrink-0 cursor-pointer items-center">
                      <Link href="/" passHref>
                        <div>
                          <Image alt="Magna" src="/logo_white.svg" height={20} width={120} />
                        </div>
                      </Link>
                    </div>
                    <div className="hidden sm:ml-6 sm:block">
                      <div className="flex space-x-2">
                        {navigation.map((item) => (
                          <LinkWithProps
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-zinc-900 text-white'
                                : 'text-zinc-300 hover:bg-zinc-900 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium',
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </LinkWithProps>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <div className="flex items-center">
                          <WalletSelectButton chain={chain} />
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      as={LinkWithProps}
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-zinc-900 text-white' : 'text-zinc-300 hover:bg-zinc-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="">
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 ">
              <div className="px-4 py-8 sm:px-0">{children}</div>
            </div>
          </main>
        </div>
        <div className="mx-auto mt-10 px-2 pb-10 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-zinc-700">2022 Magna Digital Inc.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default function WrappedLayout({ children, chain }: ILayoutProps) {
  const user = useRecoilValue(userAtom);
  const resetUser = useResetRecoilState(userAtom);
  const router = useRouter();
  const { connected } = useWallet();

  const navigation: INavbarElement[] = useMemo(() => {
    const navigation = [];
    /* eslint-disable no-fallthrough */
    switch (user?.role) {
      case Role.SUPER_ADMIN:
        navigation.push({
          name: 'SuperAdmin',
          href: '/superadmin',
          current: router.pathname.startsWith('/superadmin'),
        });
      case Role.ADMIN:
        navigation.push({
          name: 'Dashboard',
          href: '/admin',
          current: router.pathname.startsWith('/admin'),
        });
        navigation.push({
          name: 'Stakeholders',
          href: '/stakeholders',
          current: router.pathname.startsWith('/stakeholders'),
        });
        navigation.push({
          name: 'Audit Log',
          href: '/log',
          current: router.pathname.startsWith('/log'),
        });
      default:
        if (chain !== CHAIN_TYPE.OTHER && user?.role) {
          navigation.push({
            name: 'Claim',
            href: '/claim',
            current: router.pathname.startsWith('/claim'),
          });
        }
    }
    /* eslint-enable no-fallthrough */
    return navigation;
  }, [chain, router.pathname, user?.role]);

  // On wallet disconnect, invalidate user cache.
  useEffect(() => {
    if (!connected) {
      resetUser();
      logoutWallet();
      window.location.pathname = '/';
    }
  }, [connected, chain, resetUser]);

  return (
    <BaseLayout chain={chain} navigation={navigation}>
      {children}
    </BaseLayout>
  );
}
