// Client-side utils for UI interactions and displays.
import { WheelEvent, useEffect } from 'react';

export const getUTCDateString = (date: Date) => {
  return (date.getUTCMonth() + 1).toString() + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
};

export const formatNumber = (x: number) => {
  if (x < 1 && x != 0.0) {
    return '< 1.0';
  }

  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const truncate = (input: string, amount = 10) =>
  input.length > 10 ? `${input.substring(0, amount)}...` : input;

export const capitalize = (text: string) => {
  return text
    .split(' ')
    .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
    .join(' ');
};

export const objectsToCSVRows = (rows: any) => {
  const unpackedRows = rows.map((row: any) => {
    const newRow = { ...row };
    delete newRow['__v'];
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === 'object' && row[key] !== null) {
        newRow[key] = JSON.stringify(row[key]);
      }
    });
    return newRow;
  });
  return unpackedRows;
};

export const useKeyboardShortcut = (key: string, action: () => void, enabled: boolean) => {
  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === key && enabled) {
        action();
      }
    }

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [key, action]);
};

export const disableScrollWheel = (e: WheelEvent<HTMLInputElement>) => e.currentTarget.blur();
