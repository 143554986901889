import { Chain } from '@rainbow-me/rainbowkit';
export const WEI_PER_ETH = 10e17 as const;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000' as const;
export const GOERLI_FAUCET_ADDRESS = '0x5D2900203fC9304BEA137Cd78878F883E8C15F1C' as const;
export const GOERLI_MAGNA_TOKEN_ADDRESS = '0x88b71e785eb153ddd25931c1ef1293c4fc5b91ff' as const;
export const ETHEREUM_CHAINS = {
  MAINNET: '1',
  GOERLI: '5',
  BNB_MAINNET: '56',
  BNB_TESTNET: '97',
  LOCAL: '31337',
} as const;

export const BNB_MAINNET: Chain = {
  id: 56,
  name: 'BNB Mainnet',
  network: 'bnb_mainnet',
  iconUrl: 'https://svgur.com/i/p6N.svg',
  nativeCurrency: {
    decimals: 8,
    name: 'BNB Coin',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://bsc-dataseed.binance.org/',
  },
  blockExplorers: {
    default: { name: 'BNB Mainnet', url: 'https://bscscan.com/' },
  },
  testnet: false,
} as const;

export const BNB_TESTNET: Chain = {
  id: 97,
  name: 'BNB Testnet',
  network: 'bnb_testnet',
  iconUrl: 'https://svgur.com/i/p6N.svg',
  nativeCurrency: {
    decimals: 8,
    name: 'BNB Coin',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  },
  blockExplorers: {
    default: { name: 'BNB Testnet', url: 'https://testnet.bscscan.com' },
  },
  testnet: false,
} as const;

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000000000000000000000000000' as const;
export const CREATE_VESTING_EVENT = [
  { type: 'uint256', name: 'vestId', indexed: true },
  { type: 'address', name: 'token' },
  { type: 'address', name: 'owner', indexed: true },
  { type: 'address', name: 'recipient', indexed: true },
  { type: 'uint32', name: 'start' },
  { type: 'uint32', name: 'cliffDuration' },
  { type: 'uint32', name: 'stepDuration' },
  { type: 'uint32', name: 'steps' },
  { type: 'uint128', name: 'cliffAmount' },
  { type: 'uint128', name: 'stepAmount' },
  { type: 'bool', name: 'fromBentoBox' },
];

export const SCHEDULE_CREATED_EVENT = [
  { type: 'address', name: 'beneficiary', indexed: true },
  { type: 'address', name: 'benefactor', indexed: false },
  { type: 'string', name: 'id', indexed: false },
];

export const SCHEDULE_CANCELLED_EVENT = [{ type: 'string', name: 'id', indexed: false }];

export const TRANSACTION_TYPE = {
  START: 'START',
  CANCEL: 'CANCEL',
} as const;

export const DEPOSITED_TO_ESCROW_EVENT = [{ type: 'uint256', name: 'amount', indexed: false }];

export const FUNCTION_SIGNATURES: { [key: string]: string } = {
  DEPLOY: '22bfc6e9',
  START: '42974827',
  CANCEL: '49784a76',
  APPROVE: '095ea7b3',
  FUND: 'b6f02b6f',
};
