import * as borsh from '@project-serum/borsh';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey } from '@solana/web3.js';
import { NativeStream, TokenStream, ZEBEC_PROGRAM_ID } from '@zebec-protocol/stream';
import type {
  DepositWithdrawSol,
  InitNativeStream,
  PauseResumeCancelNativeStream,
  StreamTransactionResponse,
  WithdrawNativeStream,
} from '@zebec-protocol/stream';

import { LogAPI } from '../data-hooks/log-api';
import { STATUS, StatusValue } from './constants/distribution';
import { TRANSACTION_TYPE } from './constants/logger';

const ZEBEC_STREAM_DATA_LAYOUT = borsh.struct([
  borsh.u64('start_time'),
  borsh.u64('end_time'),
  borsh.u64('paused'),
  borsh.u64('withdrawn'),
  borsh.u64('withdraw_limit'),
  borsh.u64('amount'),
  borsh.publicKey('sender'),
  borsh.publicKey('recipient'),
]);

function getStream(wallet: AnchorWallet): NativeStream;
function getStream(wallet: AnchorWallet, token?: string): TokenStream;
function getStream(wallet: AnchorWallet, token?: string): TokenStream | NativeStream {
  if (token) return new TokenStream(wallet, process.env.NEXT_PUBLIC_SOLANA_RPC_URL);
  else return new NativeStream(wallet, process.env.NEXT_PUBLIC_CHAIN);
}

type ZebecRequest = (InitNativeStream | PauseResumeCancelNativeStream | WithdrawNativeStream | DepositWithdrawSol) & {
  token?: string;
};
function createTransactionLog(request: ZebecRequest, response: StreamTransactionResponse, action: string): void {
  const logAPI = new LogAPI();
  logAPI.createTransactionLog({
    log: {
      meta: {
        onChainId: response.data.pda,
        transactionHash: response.data.transactionHash,
      },
      requestJson: request,
      responseJson: response || {},
      action,
    },
  });
}

export const getZebecBalance = async (walletAddress: string, token = ''): Promise<number> => {
  try {
    const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
    const base58PublicKey = new PublicKey(ZEBEC_PROGRAM_ID);
    const walletPublicKey = new PublicKey(walletAddress);
    const tokenMint = new PublicKey(token);
    const [zebecWallet] = PublicKey.findProgramAddressSync([walletPublicKey.toBuffer()], base58PublicKey);
    const { value: account } = await connection.getParsedTokenAccountsByOwner(zebecWallet, { mint: tokenMint });
    if (account.length === 0) return 0.0;
    return account[0].account.data.parsed.info.tokenAmount.uiAmount;
  } catch (error) {
    console.error(error);
    return 0.0;
  }
};

export const getZebecStreamInfo = async (pda = '') => {
  const streamPublicKey = new PublicKey(pda);
  const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
  const streamInfo = await connection.getAccountInfo(streamPublicKey);

  try {
    const streamInfoState = ZEBEC_STREAM_DATA_LAYOUT.decode(streamInfo?.data);
    const currentTime = Math.floor(Date.now() / 1000);
    const total_duration_in_seconds = parseInt(streamInfoState.end_time) - parseInt(streamInfoState.start_time);
    const remaining_duration_in_seconds = Math.max(parseInt(streamInfoState.end_time) - currentTime, 0);
    const spent_time_in_seconds = total_duration_in_seconds - remaining_duration_in_seconds;

    const parsedStreamState = {
      startTime: parseInt(streamInfoState.start_time),
      endTime: parseInt(streamInfoState.end_time),
      amount: parseInt(streamInfoState.amount),
      paused: parseInt(streamInfoState.paused),
      withdrawnAmount: parseInt(streamInfoState.withdrawn),
      withdrawLimit: parseInt(streamInfoState.withdraw_limit),
      sender: streamInfoState.sender.toString(),
      recipient: streamInfoState.recipient.toString(),
      result: {
        total_duration_in_seconds,
        spent_time_in_seconds,
        remaining_duration_in_seconds,
      },
    };

    let status: StatusValue = STATUS.IN_PROGRESS;
    if (currentTime < parsedStreamState.startTime) {
      status = STATUS.SCHEDULED;
    } else if (currentTime > parsedStreamState.endTime) {
      status = STATUS.COMPLETED;
    }

    return { status, ...parsedStreamState };
  } catch {
    return { status: 'CANCELLED' };
  }
};

export const depositToken = async (wallet: AnchorWallet, sender: string, amount: number, token?: string) => {
  const request: DepositWithdrawSol = {
    sender,
    amount,
  };
  const stream = getStream(wallet, token);
  const response = await stream.deposit({ ...request, token });
  createTransactionLog(request, response, TRANSACTION_TYPE.DEPOSIT);
  return response;
};
export const depositTransac = depositToken;

export const initStream = async (
  wallet: AnchorWallet,
  sender: string,
  receiver: string,
  amount: number,
  start_time: number,
  end_time: number,
  token?: string,
) => {
  const request: InitNativeStream = {
    sender,
    receiver,
    amount,
    start_time,
    end_time,
  };
  const stream = getStream(wallet, token);
  const response = await stream.init({ ...request, token });
  createTransactionLog(request, response, TRANSACTION_TYPE.CREATE_STREAM);
  return response;
};
export const createZebecStream = initStream;

export const cancelStream = async (
  wallet: AnchorWallet,
  sender: string,
  receiver: string,
  pda: string,
  token?: string,
) => {
  const request: PauseResumeCancelNativeStream = {
    sender,
    receiver,
    pda,
  };
  const stream = getStream(wallet, token);
  const response = await stream.cancel({ ...request, token });
  createTransactionLog(request, response, TRANSACTION_TYPE.CANCEL_STREAM);
  return response;
};
export const cancelZebecStream = cancelStream;

export const withdrawStreamedToken = async (
  wallet: AnchorWallet,
  sender: string,
  receiver: string,
  pda: string,
  amount: number,
  token?: string,
) => {
  const request: WithdrawNativeStream = {
    sender,
    receiver,
    pda,
    amount,
  };
  const stream = getStream(wallet, token);
  const response = await stream.cancel({ ...request, token });
  createTransactionLog(request, response, TRANSACTION_TYPE.WITHDRAW_STREAM);
  return response;
};
export const withdrawZebecStream = withdrawStreamedToken;

export const withdrawDepositedToken = async (wallet: AnchorWallet, sender: string, amount: number, token?: string) => {
  const request: DepositWithdrawSol = {
    sender,
    amount,
  };
  const stream = getStream(wallet, token);
  const response = await stream.cancel({ ...request, token });
  createTransactionLog(request, response, TRANSACTION_TYPE.WITHDRAW);
  return response;
};
export const withdrawDeposit = withdrawDepositedToken;
