import { RainbowKitProvider, darkTheme, getDefaultWallets } from '@rainbow-me/rainbowkit';
// ethereum dependencies
import '@rainbow-me/rainbowkit/styles.css';
import { ErrorBoundary } from '@sentry/react';
// solana dependencies
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
// CX stack
import Cohere from 'cohere-js';
import * as Intercom from '@/lib/intercom';
import { AppProps } from 'next/app';
import { Suspense, useMemo } from 'react';
import { RecoilRoot } from 'recoil';

import { WagmiConfig, chain, configureChains, createClient } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import { SuspenseLoading } from '../components/LoadingWithLayout';
import Permissions from '../components/Permissions';
import { BackendAPIProvider } from '../data-hooks/api-provider';
import { ModalProvider } from '../data-hooks/modal-provider';
import '../styles/globals.css';
import { BNB_MAINNET, BNB_TESTNET } from '@/lib/constants/ethereum';

// use require instead of import since order matters
require('@solana/wallet-adapter-react-ui/styles.css');

Cohere.init(process.env.NEXT_PUBLIC_COHERE_ID);
Intercom.init();

// configure ethereum
const { chains, provider } = configureChains(
  [chain.localhost, chain.goerli, chain.mainnet, chain.polygonMumbai, chain.polygon, BNB_TESTNET, BNB_MAINNET],
  [alchemyProvider(), publicProvider()],
);

const { connectors } = getDefaultWallets({
  appName: 'Magna',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const App = ({ Component, pageProps }: AppProps) => {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = process.env.NEXT_PUBLIC_CLUSTER;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolflareWalletAdapter()], []);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <ModalProvider chain={pageProps.chain}>
                <BackendAPIProvider chain={pageProps.chain}>
                  <RecoilRoot>
                    <ErrorBoundary>
                      <Suspense fallback={<SuspenseLoading chain={pageProps.chain} />}>
                        <Permissions />
                        <Component {...pageProps} />
                      </Suspense>
                    </ErrorBoundary>
                  </RecoilRoot>
                </BackendAPIProvider>
              </ModalProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

App.getInitialProps = () => {
  return {
    pageProps: {
      chain: process.env.NEXT_PUBLIC_CHAIN,
    },
  };
};

export default App;
