import { useEffect } from 'react';

type IntercomSettings = NonNullable<typeof window.intercomSettings>;

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom

const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

if (typeof window !== 'undefined') {
  window.intercomSettings = {
    app_id: APP_ID,
  };
}

// prettier-ignore
export const init = () => {
  if (typeof window === 'undefined') return;
  /* eslint-disable */
  // @ts-expect-error
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  /* eslint-enable */
}

// Initializes Intercom
export const boot = (options: IntercomSettings = {}) => {
  window?.Intercom?.('boot', options);
};

export const update = (options: IntercomSettings = {}) => {
  window?.Intercom?.('update', options);
};

export const showLauncher = (options: IntercomSettings = {}) => {
  window?.Intercom?.('update', { hide_default_launcher: false, ...options });
};

export const hideLauncher = () => {
  window?.Intercom?.('update', { hide_default_launcher: true });
};

export const useIntercom = (options: IntercomSettings = {}) => {
  useEffect(() => {
    showLauncher(options);
    return () => hideLauncher();
  });
};

export const shutdown = () => {
  window?.Intercom?.('shutdown');
};
