import { Values } from '../types';

export const EXPLORER_TYPE = {
  TOKEN: 'token',
  ADDRESS: 'address',
  TRANSACTION: 'tx',
} as const;
export type ExplorerTypeValue = Values<typeof EXPLORER_TYPE>;

export const EXPLORER_NAME = {
  SOLANA_EXPLORER: 'Solana Explorer',
  ETHERSCAN: 'Etherscan',
  BSCSCAN: 'BscScan',
  POLYGONSCAN: 'PolygonScan',
} as const;
