import { BN } from 'bn.js';
import Web3 from 'web3';
import { TransactionReceipt } from 'web3-core';
import { AbiItem } from 'web3-utils';

import { LogAPI } from '../data-hooks/log-api';
import erc20Artifact from './airlock-eth/ERC20.json';
import factoryArtifact from './airlock-eth/Factory.json';
import vesterArtifact from './airlock-eth/PiecewiseVester.json';
import { GOERLI_FAUCET_ADDRESS, GOERLI_MAGNA_TOKEN_ADDRESS, WEI_PER_ETH, ZERO_ADDRESS } from './constants/ethereum';
import { TRANSACTION_TYPE } from './constants/logger';
import { NETWORK } from './constants/network';
import { ProviderValue } from './constants/user';
import { getNetworkFromProvider, getWeb3Provider, getTransactionReceipt, getFactoryAddress } from './ethereum';

async function getWeb3Objects(
  distribution: any,
): Promise<{ web3?: Web3; vesterContract?: any; vesterAddress?: string }> {
  try {
    const web3 = getWeb3Provider(getNetworkFromProvider(distribution.provider));

    const factoryContract = new web3.eth.Contract(
      factoryArtifact as AbiItem[],
      getFactoryAddress(distribution.provider),
    );

    // get the piecewise vester address from the factory that made it
    const vesterAddress = await factoryContract.methods.getPiecewiseVester(distribution.token.address).call();
    const vesterContract = new web3.eth.Contract(vesterArtifact as AbiItem[], vesterAddress);
    return { web3, vesterContract, vesterAddress };
  } catch (error: any) {
    throw Error(error.message);
  }
}

export const getFee = async (distribution: any, amount: any, senderAddress: string) => {
  try {
    const { vesterContract } = await getWeb3Objects(distribution);
    const fee = await vesterContract.methods
      .fee()
      .call()
      .then((response: any) => {
        console.log('id is... ', response);
      });
    return fee;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const getPiecewiseVesterContractAddress = async (erc20Address: string, provider: ProviderValue) => {
  const web3 = getWeb3Provider(getNetworkFromProvider(provider));
  const factoryContract = new web3.eth.Contract(factoryArtifact as AbiItem[], getFactoryAddress(provider));

  const vesterAddress = await factoryContract.methods.getPiecewiseVester(erc20Address).call();
  return vesterAddress;
};

export const createPiecewiseVester = async (erc20Address: string, senderAddress: string, provider: ProviderValue) => {
  const logAPI = new LogAPI();
  const web3 = getWeb3Provider(getNetworkFromProvider(provider));

  const factoryContract = new web3.eth.Contract(factoryArtifact as AbiItem[], getFactoryAddress(provider));

  const transactionParameters = {
    from: senderAddress,
    to: getFactoryAddress(provider),
    data: factoryContract.methods.createPiecewiseVester(erc20Address, 0).encodeABI(),
  };

  const transactionHash = await (window.ethereum as any)?.request({
    method: 'eth_sendTransaction',
    params: [transactionParameters],
  });

  const receipt = await getTransactionReceipt(transactionHash, web3);

  logAPI.createTransactionLog({
    log: {
      meta: {},
      requestJson: { transactionParameters },
      responseJson: {
        receipt,
      },
      action: 'DEPLOY_CONTRACT',
    },
  });

  return receipt;
};

export const getERC20Allowance = async (erc20Address: string, ownerAddress: string, provider: ProviderValue) => {
  const web3 = getWeb3Provider(getNetworkFromProvider(provider));
  const erc20Contract = new web3.eth.Contract(erc20Artifact as AbiItem[], erc20Address);
  const vesterAddress = await getPiecewiseVesterContractAddress(erc20Address, provider);
  const allowance = await erc20Contract.methods.allowance(ownerAddress, vesterAddress).call();
  return web3?.utils?.fromWei(allowance, 'ether');
};

export const approveERC20Transfer = async (
  erc20Address: string,
  approverAddress: string,
  amount: number,
  provider: ProviderValue,
) => {
  const logAPI = new LogAPI();
  const web3 = getWeb3Provider(getNetworkFromProvider(provider));
  const erc20Contract = new web3.eth.Contract(erc20Artifact as AbiItem[], erc20Address);
  const vesterAddress = await getPiecewiseVesterContractAddress(erc20Address, provider);
  const allocationInTokens = web3?.utils.toWei(amount.toString(), 'ether');

  const transactionParameters = {
    from: approverAddress,
    to: erc20Address,
    data: erc20Contract.methods.approve(vesterAddress, allocationInTokens).encodeABI(),
  };

  // should we throw error if this is undefined?
  const approvalHash = await (window.ethereum as any)?.request({
    method: 'eth_sendTransaction',
    params: [transactionParameters],
  });

  const approvalReceipt = await getTransactionReceipt(approvalHash, web3);

  logAPI.createTransactionLog({
    log: {
      meta: {},
      requestJson: { transactionParameters },
      responseJson: {
        approvalReceipt,
      },
      action: 'APPROVE_TRANSFER',
    },
  });

  return approvalReceipt;
};

export const create = async (distribution: any, senderAddress: string) => {
  const logAPI = new LogAPI();
  let onChainId;
  try {
    const { web3, vesterContract, vesterAddress } = await getWeb3Objects(distribution);

    // get the fee value from the contract
    const fee = await vesterContract.methods.fee().call();

    // get this value in wei s.t. we can pass it directly to the contract
    const allocationInTokens = web3?.utils.toWei(new BN(distribution.allocation), 'ether');

    //// this is the inverse of how we take the fee on deposit in the airlock contract (v0):
    // (allocation amount) = (deposit amount) - [((deposit amount)*(fee)) / (10000 i.e. number of basis points)]
    //
    //// as such this means we need to deposit:
    // (deposit amount) = (allocation amount) / (1 - [(fee) / (10000 i.e. number of basis points)])
    //
    // and we need to multiply prior to dividing to maintain precision in our results
    const depositAmount = allocationInTokens?.muln(10000).divn(10000 - fee);

    // pieces need to be passed in as their own arrays (for now... we could adjust the contract)
    const startDates = [];
    const periodLengths = [];
    const numberOfPeriods = [];
    const percentages = [];

    for (const piece of distribution.pieces) {
      startDates.push(piece.startTime);
      periodLengths.push(piece.periodLength);
      numberOfPeriods.push(piece.numberOfPeriods);
      percentages.push(piece.allocationPercent * 100);
    }

    // this is the scheduleId in the vester contract. May cause issues with race condition
    onChainId = await vesterContract.methods.id().call();

    if (onChainId) {
      const data = vesterContract.methods
        .create(
          distribution.stakeholder.address,
          true,
          depositAmount?.toString(),
          startDates,
          periodLengths,
          numberOfPeriods,
          percentages,
        )
        .encodeABI();

      const transactionParameters = {
        data,
        from: senderAddress,
        to: vesterAddress,
      };

      const transactionHash = await (window.ethereum as any)?.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });

      const createReceipt = await getTransactionReceipt(transactionHash, web3!);

      if (createReceipt) {
        logAPI.createTransactionLog({
          log: {
            meta: { onChainId: onChainId!.toString(), transactionHash },
            requestJson: { distribution },
            responseJson: {
              createReceipt,
              transactionHash,
            },
            action: TRANSACTION_TYPE.START_STREAM,
          },
        });

        return { onChainId, transactionHash };
      }
    }
  } catch (error) {
    logAPI.createTransactionLog({
      log: {
        meta: { onChainId },
        requestJson: { distribution },
        responseJson: {
          error,
        },
        action: TRANSACTION_TYPE.START_STREAM,
      },
    });

    throw Error((error as Error).message);
  }

  return {};
};

export const withdraw = async (distribution: any, senderAddress: string) => {
  const logAPI = new LogAPI();

  try {
    const { web3, vesterContract, vesterAddress } = await getWeb3Objects(distribution);

    const transactionParameters = {
      from: senderAddress,
      to: vesterAddress,
      data: vesterContract.methods.withdrawReleasableAmount(distribution.pieces[0].onChainId).encodeABI(),
    };

    // should we throw error if this is undefined?
    const transactionHash = await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    const receipt = await getTransactionReceipt(transactionHash, web3!);

    if (receipt) {
      logAPI.createWithdrawalLog({
        log: {
          meta: { onChainId: distribution.pieces[0].onChainId!.toString(), transactionHash },
          requestJson: { distribution },
          responseJson: {
            receipt,
            transactionHash,
          },
          action: TRANSACTION_TYPE.WITHDRAW,
        },
      });

      return { transactionHash };
    }
  } catch (error: any) {
    logAPI.createWithdrawalLog({
      log: {
        meta: {},
        requestJson: { distribution },
        responseJson: {
          error,
        },
        action: TRANSACTION_TYPE.WITHDRAW,
      },
    });
    throw Error(error.message);
  }
  return {};
};

export const getERC20Balance = async (walletAddress: string, tokenAddress: string, provider: ProviderValue) => {
  try {
    const web3 = getWeb3Provider(getNetworkFromProvider(provider));
    const erc20Contract = new web3.eth.Contract(erc20Artifact as AbiItem[], tokenAddress);
    const balance = await erc20Contract.methods.balanceOf(walletAddress).call();

    return parseInt(balance) / WEI_PER_ETH;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export interface SandboxWalletEvent {
  event: 'goerli' | 'test_token';
  hash: TransactionReceipt;
}

export const initializeSandboxWallet = async (
  walletAddress: string,
  callback?: (event: SandboxWalletEvent) => void,
): Promise<void> => {
  try {
    const web3 = getWeb3Provider(NETWORK.GOERLI);

    // send Goerli Eth to the user
    const gethTransaction = {
      from: GOERLI_FAUCET_ADDRESS,
      to: walletAddress,
      value: web3.utils.toWei('1', 'ether'),
    };

    const rawGethTransaction = (
      await web3.eth.accounts.signTransaction(
        gethTransaction,
        process.env.NEXT_PUBLIC_GOERLI_FAUCET_PRIVATE_KEY as string,
      )
    ).rawTransaction;

    const hash = await web3.eth.sendSignedTransaction(rawGethTransaction as any);
    callback?.({ event: 'goerli', hash });

    // send 11000 test tokens to this person to demo with
    const erc20Contract = new web3.eth.Contract(erc20Artifact as AbiItem[], GOERLI_MAGNA_TOKEN_ADDRESS);

    const magnaTokenTransactionParameters = {
      from: GOERLI_FAUCET_ADDRESS,
      to: GOERLI_MAGNA_TOKEN_ADDRESS,
      data: erc20Contract.methods.transfer(walletAddress, web3.utils.toWei('11000', 'ether')).encodeABI(),
    };

    const rawERC20Transaction = (
      await web3.eth.accounts.signTransaction(
        magnaTokenTransactionParameters,
        process.env.NEXT_PUBLIC_GOERLI_FAUCET_PRIVATE_KEY as string,
      )
    ).rawTransaction;

    web3.eth.sendSignedTransaction(rawERC20Transaction as any).on('confirmation', (_, receipt) => {
      callback?.({ event: 'test_token', hash: receipt });
    });
  } catch (e) {
    const error = e as Error;
    throw Error(error.message);
  }
};

/* gets the number of ERC20 tokens that have been both (1) approved to be spent
 * by the contract and (2) deposited to the contract */
export const getEscrowBalance = async (distribution: any) => {
  try {
    const { vesterContract, vesterAddress } = await getWeb3Objects(distribution);

    if (vesterAddress == ZERO_ADDRESS) {
      return 0;
    }

    const escrowBalance = await vesterContract.methods.getEscrowBalance().call({ from: distribution.owner.address });
    return parseInt(escrowBalance) / Math.pow(10, 18);
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const withdrawFromEscrow = async (distribution: any, amount: number, senderAddress: string) => {
  const logAPI = new LogAPI();
  const { web3, vesterContract, vesterAddress } = await getWeb3Objects(distribution);

  const allocationInTokens = web3?.utils.toWei(new BN(amount), 'ether');

  const transactionParameters = {
    from: senderAddress,
    to: vesterAddress,
    data: vesterContract.methods.withdraw(allocationInTokens).encodeABI(),
  };

  const transactionHash = await (window.ethereum as any)?.request({
    method: 'eth_sendTransaction',
    params: [transactionParameters],
  });

  const receipt = await getTransactionReceipt(transactionHash, web3!);

  if (receipt) {
    logAPI.createTransactionLog({
      log: {
        meta: { transactionHash },
        requestJson: { distribution, amount },
        responseJson: {
          receipt,
          transactionHash,
        },
        action: TRANSACTION_TYPE.WITHDRAW,
      },
    });

    return { transactionHash };
  }
};

/* this should be standardized to distribution during feature freeze */
export const cancel = async (params: any) => {
  const logAPI = new LogAPI();
  try {
    const { web3, vesterContract, vesterAddress } = await getWeb3Objects(params);

    const transactionParameters = {
      from: params.sender,
      to: vesterAddress,
      data: vesterContract.methods.cancelSchedule(params.onChainId).encodeABI(),
    };

    const transactionHash = await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    const receipt = await getTransactionReceipt(transactionHash, web3!);

    if (receipt) {
      logAPI.createTransactionLog({
        log: {
          meta: { onChainId: params.onChainId, transactionHash },
          requestJson: { distribution: params },
          responseJson: {
            receipt,
            transactionHash,
          },
          action: TRANSACTION_TYPE.CANCEL_STREAM,
        },
      });

      return { transactionHash };
    }
  } catch (error: any) {
    logAPI.createTransactionLog({
      log: {
        meta: {},
        requestJson: { distribution: params },
        responseJson: {
          error,
        },
        action: TRANSACTION_TYPE.CANCEL_STREAM,
      },
    });
    throw Error(error.message);
  }
  return {};
};

/* used to get the amount withdrawn */
export const getOne = async (distribution: any) => {
  try {
    const { vesterContract } = await getWeb3Objects(distribution);
    const schedule = await vesterContract.methods.getScheduleMetadata(distribution.pieces[0].onChainId).call();
    return schedule;
  } catch (err: any) {
    return null;
  }
};

// note: this is the main function that's use for status fetching (i.e. main distribution API route)
export const getMany = async (distributions: any[]) => {
  const { web3, vesterContract } = await getWeb3Objects(distributions[0]);

  const scheduleQueries = await Promise.allSettled(
    distributions.map((distribution) => {
      if (distribution.pieces[0].onChainId) {
        return vesterContract.methods.getScheduleMetadata(distribution.pieces[0].onChainId).call();
      }
    }),
  );

  const schedules = scheduleQueries.map((query) =>
    query.status === 'fulfilled' && query.value ? { ...query.value } : null,
  );

  const serializedSchedules = schedules.map((schedule) => {
    if (!schedule) return null;
    return {
      // values are returned as an array from ethers
      cancelled: schedule[1],
      withdrawn: schedule[6] ? parseFloat(web3?.utils?.fromWei(schedule[6], 'ether') as string) : 0,
    };
  });

  return serializedSchedules;
};

export function getOnChainId(distribution: any) {
  throw new Error('Function not implemented.');
}
