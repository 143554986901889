import * as anchor from '@project-serum/anchor';
import * as splToken from '@solana/spl-token';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, sendAndConfirmRawTransaction } from '@solana/web3.js';

import { LogAPI } from '../data-hooks/log-api';
import idl from '../public/airlock-sol-idl.json';
import { TRANSACTION_TYPE } from './constants/logger';
import { getTokenMint, signAndSendTransactions } from './solana';

const programId = new PublicKey('magnaVezybsnxCpeWsrYbJSr1qvzN2keHHHLYdAfinA');
const feeDestinationWallet = new PublicKey('CEnmUxjcuqHAjgeLKVzbvfuF2LZUqdQoBYGaA8AH4FqT');
const PIECEWISE_SEED = 'PIECEWISE';

const ONE_PERCENT_VALUE = 10_000_000;
const BPS_PER_PERCENT = 100;
const BPS_PER_WHOLE = BPS_PER_PERCENT * 100;

type OnChainAirlockSOLObject = {
  withdrawn: anchor.BN;
};

const getOnChainParameters = async (connection: Connection, distribution: any, senderAddress: string) => {
  const tokenMint = await splToken.getMint(connection, new PublicKey(distribution.token.address));
  const beneficiary = new PublicKey(distribution.stakeholder.address);
  const benefactor = new PublicKey(senderAddress);
  const benefactorAta = await splToken.getAssociatedTokenAddress(tokenMint.address, benefactor, true);
  const beneficiaryAta = await splToken.getAssociatedTokenAddress(tokenMint.address, beneficiary, true);
  const feeDestination = await splToken.getAssociatedTokenAddress(tokenMint.address, feeDestinationWallet, true);

  const newSchedule = {
    scheduleId: distribution._id.toString(),
    cancellable: true,
    beneficiary: beneficiary,
    benefactor: benefactor,
    tokenMint: tokenMint.address,
    allocation: (() => {
      // the "raw" calculation depends on the number of decimals for the token since solana doesn't support floating point values
      const decimalPrecisionMultiplier = new anchor.BN(Math.pow(10, tokenMint.decimals));
      const bpsPerWhole = new anchor.BN(BPS_PER_WHOLE);

      const allocationInTokens = new anchor.BN(distribution.allocation);
      const rawAllocation = allocationInTokens.mul(decimalPrecisionMultiplier);
      const feeInBps = new anchor.BN(distribution.owner.feeBps);
      const rawFee = rawAllocation.mul(feeInBps).div(bpsPerWhole);

      return rawAllocation.add(rawFee);
    })(),
    withdrawn: new anchor.BN(0),
    feeBps: new anchor.BN(distribution.owner.feeBps),
    feeDestination,
    pieces: distribution.pieces.map((piece: any) => {
      return {
        startDate: new anchor.BN(piece.startTime),
        periodLength: new anchor.BN(piece.periodLength),
        numberOfPeriods: new anchor.BN(piece.numberOfPeriods),
        // note: don't need to worry about overflow since the value is bounded
        allocationPercentage: new anchor.BN(piece.allocationPercent * ONE_PERCENT_VALUE * 100),
      };
    }),
  };

  const scheduleAddress = await getOnChainId(distribution);
  const scheduleAta = await splToken.getAssociatedTokenAddress(tokenMint.address, scheduleAddress!, true);
  return {
    tokenMint,
    beneficiary,
    beneficiaryAta,
    benefactor,
    benefactorAta,
    feeDestination,
    newSchedule,
    scheduleAddress,
    scheduleAta,
  };
};

function withAnchor(wallet: AnchorWallet) {
  const network = process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string;
  const connection = new Connection(network, 'confirmed');

  const provider = new anchor.AnchorProvider(connection, wallet, {
    preflightCommitment: 'recent',
    commitment: 'recent',
  });

  const program = new anchor.Program(idl as anchor.Idl, programId, provider);

  return { program, connection };
}

export const bulkCreate = async (wallet: AnchorWallet, distributions: any[], senderAddress: string) => {
  const { connection, program } = withAnchor(wallet);
  const logAPI = new LogAPI();

  try {
    const { blockhash } = await connection.getLatestBlockhash('finalized');

    const transactions = await Promise.all(
      distributions.map(async (distribution: any) => {
        const {
          tokenMint,
          beneficiary,
          benefactor,
          benefactorAta,
          feeDestination,
          newSchedule,
          scheduleAddress,
          scheduleAta,
        } = await getOnChainParameters(connection, distribution, senderAddress);

        const instruction = await program.methods
          .create(newSchedule, distribution._id, true)
          .accounts({
            tokenMint: tokenMint.address,
            benefactor,
            benefactorAta,
            beneficiary,
            schedule: scheduleAddress,
            scheduleAta,
            feeDestination,
          })
          .instruction();

        const transactionInstructions = new Transaction();
        transactionInstructions.recentBlockhash = blockhash;
        transactionInstructions.feePayer = wallet.publicKey;
        transactionInstructions.add(instruction);

        return { transactionInstructions, onChainId: scheduleAddress };
      }),
    );

    const signedTransactions = await wallet.signAllTransactions(
      transactions.map((transaction) => transaction.transactionInstructions),
    );

    const onChainIds = transactions.map((transaction) => transaction.onChainId);
    const transactionHashResults = await Promise.allSettled(
      signedTransactions.map((signedTransaction) =>
        sendAndConfirmRawTransaction(connection, signedTransaction.serialize(), {
          commitment: 'confirmed',
          maxRetries: 5,
        }),
      ),
    );

    const transactionHashes = transactionHashResults.map((transactionHashResult) =>
      transactionHashResult.status === 'fulfilled' ? transactionHashResult.value : null,
    );

    await logAPI.createTransactionLog({
      log: {
        meta: { transactionHash: transactionHashes.find(Boolean) ?? undefined },
        requestJson: { transactions },
        responseJson: {
          transactionHashes,
          onChainIds,
        },
        action: TRANSACTION_TYPE.BULK_CREATE_STREAM,
      },
    });

    return { transactionHashes, onChainIds };
  } catch (error: any) {
    console.log(error);
    await logAPI.createTransactionLog({
      log: {
        meta: {},
        requestJson: {},
        responseJson: { error },
        action: TRANSACTION_TYPE.BULK_CREATE_STREAM,
      },
    });
    return { error };
  }
};

export const create = async (wallet: AnchorWallet, distribution: any, senderAddress: string) => {
  const logAPI = new LogAPI();
  let transactionHash = undefined;

  try {
    const { connection, program } = withAnchor(wallet);
    const {
      tokenMint,
      beneficiary,
      benefactor,
      benefactorAta,
      feeDestination,
      newSchedule,
      scheduleAddress,
      scheduleAta,
    } = await getOnChainParameters(connection, distribution, senderAddress);

    transactionHash = await program.methods
      .create(newSchedule, distribution._id, true)
      .accounts({
        tokenMint: tokenMint.address,
        benefactor,
        benefactorAta,
        beneficiary,
        schedule: scheduleAddress,
        scheduleAta,
        feeDestination,
      })
      .rpc({ commitment: 'confirmed', maxRetries: 5 });

    logAPI.createTransactionLog({
      log: {
        meta: { onChainId: scheduleAddress!.toString(), transactionHash },
        requestJson: { distribution, newSchedule },
        responseJson: {
          scheduleAddress,
          scheduleAta,
          transactionHash,
        },
        action: TRANSACTION_TYPE.START_STREAM,
      },
    });
    return { transactionHash, onChainId: scheduleAddress!.toString() };
  } catch (error: any) {
    await logAPI.createTransactionLog({
      log: {
        meta: { transactionHash },
        requestJson: { distribution, senderAddress },
        responseJson: { error },
        action: TRANSACTION_TYPE.START_STREAM,
      },
    });

    return { transactionHash, error };
  }
};

export const bulkCancel = async (wallet: AnchorWallet, distributions: any[]) => {
  const logAPI = new LogAPI();
  const { connection, program } = withAnchor(wallet);

  try {
    const { blockhash } = await connection.getLatestBlockhash('finalized');

    const transactionsToAttempt = await Promise.all(
      distributions.map(async (distribution: any) => {
        const {
          tokenMint,
          beneficiary,
          beneficiaryAta,
          benefactor,
          benefactorAta,
          feeDestination,
          scheduleAddress,
          scheduleAta,
        } = await getOnChainParameters(connection, distribution, distribution.owner.address);

        const instruction = await program.methods
          .cancel()
          .accounts({
            schedule: scheduleAddress,
            scheduleAta,
            beneficiary,
            beneficiaryAta,
            benefactor,
            benefactorAta,
            feeDestination,
            tokenMint: tokenMint.address,
          })
          .instruction();

        const transaction = new Transaction();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = wallet.publicKey;
        transaction.add(instruction);

        return { transaction, onChainId: scheduleAddress };
      }),
    );

    const transactions = transactionsToAttempt.map((transactionToAttempt) => transactionToAttempt.transaction);
    const onChainIds = transactionsToAttempt.map((transactionToAttempt) => transactionToAttempt.onChainId);
    const { transactionHashes } = await signAndSendTransactions(connection, wallet, transactions);

    await logAPI.createTransactionLog({
      log: {
        meta: { transactionHash: transactionHashes.find(Boolean) ?? undefined },
        requestJson: { transactions },
        responseJson: {
          transactionHashes,
          onChainIds,
        },
        action: TRANSACTION_TYPE.BULK_CANCEL_STREAM,
      },
    });

    return { transactionHashes, onChainIds };
  } catch (error: any) {
    console.log(error);
    await logAPI.createTransactionLog({
      log: {
        meta: {},
        requestJson: {},
        responseJson: { error },
        action: TRANSACTION_TYPE.BULK_CANCEL_STREAM,
      },
    });
    return { error };
  }
};

export const cancel = async (wallet: AnchorWallet, distribution: any) => {
  const logAPI = new LogAPI();
  let transactionHash = undefined;

  try {
    const { connection, program } = withAnchor(wallet);

    const tokenMint = await splToken.getMint(connection, new PublicKey(distribution.tokenAddress));
    const benefactor = new PublicKey(distribution.sender);
    const beneficiary = new PublicKey(distribution.recipientAddress);
    const benefactorAta = await splToken.getAssociatedTokenAddress(tokenMint.address, benefactor, true);
    const beneficiaryAta = await splToken.getAssociatedTokenAddress(tokenMint.address, beneficiary, true);
    const feeDestination = await splToken.getAssociatedTokenAddress(tokenMint.address, feeDestinationWallet, true);

    const scheduleAddress = await getOnChainId(distribution);
    const scheduleAta = await splToken.getAssociatedTokenAddress(tokenMint.address, scheduleAddress!, true);

    transactionHash = await program.methods
      .cancel()
      .accounts({
        schedule: scheduleAddress,
        scheduleAta,
        beneficiary,
        beneficiaryAta,
        benefactor,
        benefactorAta,
        feeDestination,
        tokenMint: tokenMint.address,
      })
      .rpc({ commitment: 'confirmed', maxRetries: 5 });

    logAPI.createTransactionLog({
      log: {
        meta: { onChainId: scheduleAddress!.toString(), transactionHash },
        requestJson: { distribution },
        responseJson: {
          scheduleAddress,
          scheduleAta,
          transactionHash,
        },
        action: TRANSACTION_TYPE.CANCEL_STREAM,
      },
    });

    return { transactionHash };
  } catch (error: any) {
    logAPI.createTransactionLog({
      log: {
        meta: { transactionHash },
        requestJson: { distribution },
        responseJson: { error },
        action: TRANSACTION_TYPE.CANCEL_STREAM,
      },
    });

    return { transactionHash, error };
  }
};

export const withdraw = async (wallet: AnchorWallet, distribution: any) => {
  const logAPI = new LogAPI();
  let transactionHash = undefined;

  try {
    const { connection, program } = withAnchor(wallet);

    const benefactor = new PublicKey(distribution.owner.address);
    const beneficiary = new PublicKey(distribution.stakeholder.address);
    const tokenMint = await splToken.getMint(connection, new PublicKey(distribution.token.address));
    const beneficiaryAta = await splToken.getAssociatedTokenAddress(tokenMint.address, beneficiary, true);
    const feeDestination = await splToken.getAssociatedTokenAddress(tokenMint.address, feeDestinationWallet, true);

    const scheduleAddress = await getOnChainId(distribution);

    const scheduleAta = await splToken.getAssociatedTokenAddress(tokenMint.address, scheduleAddress!, true);
    transactionHash = await program.methods
      .withdrawAvailable()
      .accounts({
        schedule: scheduleAddress,
        scheduleAta,
        beneficiary,
        beneficiaryAta,
        feeDestination,
        signer: wallet.publicKey,
        tokenMint: tokenMint.address,
      })
      .rpc({ commitment: 'confirmed', maxRetries: 5 });

    logAPI.createWithdrawalLog({
      log: {
        meta: { onChainId: scheduleAddress!.toString(), transactionHash },
        requestJson: { distribution },
        responseJson: {
          scheduleAddress,
          scheduleAta,
          transactionHash,
        },
        action: TRANSACTION_TYPE.WITHDRAW,
      },
    });

    return { transactionHash };
  } catch (error: any) {
    console.log(error);
    logAPI.createWithdrawalLog({
      log: {
        meta: { transactionHash },
        requestJson: { distribution },
        responseJson: { error },
        action: TRANSACTION_TYPE.WITHDRAW,
      },
    });

    return { transactionHash, error };
  }
};

export const getOne = async (distribution: any, debug = false) => {
  try {
    const scheduleAddress = await getOnChainId(distribution);
    const { program } = withAnchor({} as anchor.Wallet);
    const account = await program.account.piecewiseSchedule.fetch(scheduleAddress!);
    return account as OnChainAirlockSOLObject;
  } catch (err) {
    if (debug) {
      console.log(err);
    }

    return null;
  }
};

export const getMany = async (distributions: any[]) => {
  try {
    const { program } = withAnchor({} as anchor.Wallet);
    const tokenMint = await getTokenMint(distributions[0].token.address);
    const scheduleAddresses = await Promise.all(distributions.map((distribution) => getOnChainId(distribution)));

    // Keep track of indexes with missing on chain IDs -- we add them back after the call to fetchMultiple
    const indexOfUndefined: number[] = scheduleAddresses.reduce((arr: number[], curr, index) => {
      if (curr === undefined) {
        arr.push(index);
      }
      return arr;
    }, []);

    const filteredScheduledAddresses = scheduleAddresses.filter((x) => x != undefined);

    const scheduleAddressBatches = [...Array(Math.ceil(filteredScheduledAddresses.length / 100))].map((_) =>
      filteredScheduledAddresses.splice(0, 100),
    );

    // We can't have any null values in fetchMultiple
    const requests = scheduleAddressBatches.map((batch) =>
      program.account.piecewiseSchedule.fetchMultiple(batch.map((address) => address!)),
    );
    const batchedAccounts = await Promise.all(requests);
    const accounts = batchedAccounts.flat() as (OnChainAirlockSOLObject | undefined)[];

    const accountsWithData = accounts.map((account) => {
      if (!account) return null;
      return {
        ...account,
        withdrawn: account?.withdrawn.toNumber() / 10 ** tokenMint.decimals,
      };
    });

    // Add back the nulls in the correct places now that fetchMultiple has finished
    indexOfUndefined.map((x) => {
      accountsWithData.splice(x, 0, null);
    });
    return accountsWithData;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getOneFromAddress = async (scheduleAddress: string) => {
  try {
    const { program } = withAnchor({} as anchor.Wallet);
    const account = await program.account.piecewiseSchedule.fetch(scheduleAddress);
    return account as OnChainAirlockSOLObject;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getOnChainId = async (distribution: any) => {
  if (distribution.pieces[0].onChainId) {
    return new PublicKey(distribution.pieces[0].onChainId as string);
  }

  try {
    const benefactor = new PublicKey(distribution.owner.address);
    const beneficiary = new PublicKey(distribution.stakeholder.address);

    const [scheduleAddress, _] = await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from(PIECEWISE_SEED),
        benefactor.toBuffer(),
        beneficiary.toBuffer(),
        new PublicKey(distribution.token.address).toBuffer(),
        Buffer.from(distribution._id.toString()),
      ],
      programId,
    );

    return scheduleAddress;
  } catch (err) {
    return undefined;
  }
};
