export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const unixToDateString = (time: number) => {
  const date = new Date(time * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const durationToString = (time: number): string => {
  if (time === 1) return '1 second';
  else if (time < 60) return time + ' seconds';
  else if (time === 60) return '1 minute';
  else if (time < 60 * 60) return (time / 60).toFixed(2) + ' minutes';
  else if (time === 60 * 60) return '1 hour';
  else if (time < 60 * 60 * 24) return (time / 60 / 60).toFixed(2) + ' hours';
  else if (time === 60 * 60 * 24) return '1 day';
  else return (time / 60 / 60 / 24).toFixed(2) + ' days';
};
