import { Values } from '../types';

export const Provider = {
  ZEBEC: 'ZEBEC',
  STREAMFLOW: 'STREAMFLOW',
  AIRLOCK_ETH: 'AIRLOCK_ETH',
  AIRLOCK_BNB_MAINNET_V0: 'AIRLOCK_BNB_MAINNET_V0',
  AIRLOCK_BNB_TESTNET_V0: 'AIRLOCK_BNB_TESTNET_V0',
  AIRLOCK_ETH_V1: 'AIRLOCK_ETH_V1',
  AIRLOCK_POLYGON_V0: 'AIRLOCK_POLYGON_V0',
  AIRLOCK_POLYGON_V1: 'AIRLOCK_POLYGON_V1',
  AIRLOCK_MUMBAI_V0: 'AIRLOCK_MUMBAI_V0',
  AIRLOCK_MUMBAI_V1: 'AIRLOCK_MUMBAI_V1',
  AIRLOCK_GOERLI_V1: 'AIRLOCK_GOERLI_V1',
  AIRLOCK_SOL: 'AIRLOCK_SOL',
  FURO: 'FURO',
} as const;
export type ProviderValue = Values<typeof Provider>;

export const Tier = {
  LITE: 'LITE',
  PRO: 'PRO',
} as const;
export type TierValue = Values<typeof Tier>;

//todo: rethink google auth user role vs tier logic
export const Role = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
} as const;
export type RoleValue = Values<typeof Role>;
