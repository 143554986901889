import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';

import { disableScrollWheel } from '@/lib/ui';
import { SMSStatus } from '@/data-hooks/auth-api';

/**
 * A react modal component which prompts the user for an auth code. The modal
 * validates that the code is at most 6 digits, as well as shows error messages
 * as prompted/signaled by props.
 *
 * # Props
 * status?: SMSStatus - If the status is 'pending', the retry counter is
 * incremented on reopen. (this is a side effect.) If the status is 'rejected',
 * the modal is locked. Otherwise, reset Modal state.
 */

const TWILIO_CODE_LENGTH = 6;
enum SMSModalError {
  IS_RETRY = 'Incorrect code. Please try again.',
  WRONG_LENGTH = 'Your auth code must be be 6 digits long.',
  RETRY_LIMIT = 'You have attempted to verify a code more than 5 times and have reached the limit. Please try again in 10 minutes.',
}

interface ISMSModalProps {
  isOpen: boolean;
  status?: SMSStatus;
  resolve?: (code: string) => void;
  reject?: (error?: Error) => void;
}

export function SMSModal({ resolve, reject, isOpen, status }: ISMSModalProps) {
  const [code, setCode] = useState<string>('');
  const [retries, setRetries] = useState<number>(0);
  const [error, setError] = useState<SMSModalError>();

  useEffect(() => {
    if (isOpen) {
      setCode('');
      const currentRetries = retries + 1;
      if (status === 'pending' && currentRetries < 5) {
        setRetries(currentRetries);
        setError(SMSModalError.IS_RETRY);
      } else if (status === 'rejected' || currentRetries >= 5) {
        setError(SMSModalError.RETRY_LIMIT);
      } else {
        setError(undefined);
      }
    } else {
      if (error) reject && reject(new Error(error));
    }
  }, [isOpen, reject, error, retries, status]);

  const handleSubmit = () => {
    if (code.length !== TWILIO_CODE_LENGTH) {
      setError(SMSModalError.WRONG_LENGTH);
    } else {
      resolve && resolve(code);
    }
  };

  const hitRetryLimit = error === SMSModalError.RETRY_LIMIT;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => reject && reject()}>
        <div
          className="min-ch-screen flex items-end justify-center px-4 pt-4 pb-20 text-center
          sm:block sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-5 pb-4
              text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg
              sm:p-6 sm:align-middle"
            >
              <div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-100">
                    Just one more step...
                  </Dialog.Title>
                  <p className="mt-1 text-sm text-gray-500">
                    Please confirm your public address by entering the authentication code we sent to your cell #.
                  </p>
                </div>

                {error && (
                  <div className="mb-2 mt-4 rounded-md bg-red-500 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-red-100" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-red-100">
                          <p>{error}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-84 relative mx-auto mt-4 rounded-md shadow-sm">
                  <input
                    type="number"
                    onWheel={disableScrollWheel}
                    disabled={hitRetryLimit}
                    className="block w-full appearance-none rounded-md bg-zinc-900 pr-12 text-gray-100 sm:text-sm"
                    value={code}
                    onChange={(e) => e.target.value.length <= TWILIO_CODE_LENGTH && setCode(e.target.value)}
                  />
                </div>
                <div className="mt-6 flex justify-center">
                  <button
                    disabled={hitRetryLimit}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md 
                    bg-gradient-to-br from-indigo-800 to-orange-600 px-4 py-2 text-zinc-200 shadow-sm hover:from-indigo-900 hover:to-orange-800 sm:col-start-2 sm:text-sm"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SMSModal;
