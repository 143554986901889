import Cookies from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  UseGoogleLoginResponse,
  useGoogleLogin,
} from 'react-google-login';

// TODO: not sure why process.env.GOOGLE_AUTH_CLIENT_ID didnt work. need to investigate
const GOOGLE_AUTH_CLIENT_ID = '466536409067-lchqnnp08f9hlbbmpmh4di1v99of7jmj.apps.googleusercontent.com';

const onFailure = (response: UseGoogleLoginResponse) => {
  // TODO: handle google auth failure
  console.error(response);
};

const onLogout = (setAuth: (arg: boolean) => void, router: NextRouter) => {
  setAuth(false);
  router.push('/logout');
};

const onClickLoginButton = (signIn: () => void) => {
  signIn();
};

export default function GoogleAuthButton() {
  const [isAuth, setAuth] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const router = useRouter();

  const onSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    Cookies.set('GAUTH', (response as GoogleLoginResponse).accessToken, { expires: 1 });
    Cookies.set('GMAIL', (response as GoogleLoginResponse).profileObj.email, { expires: 1 });
    Cookies.set('GNAME', (response as GoogleLoginResponse).profileObj.givenName, { expires: 1 });
    router.reload();
  };

  const { signIn } = useGoogleLogin({
    clientId: GOOGLE_AUTH_CLIENT_ID,
    onSuccess,
    onFailure,
  });

  useEffect(() => {
    setAuth(Cookies.get('GAUTH') !== undefined);
    setName(Cookies.get('GNAME') || '');
  }, []);

  return isAuth ? (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <div className="text-gray-300">Welcome, {name}!</div>
      <button
        className="ml-6 cursor-pointer rounded-md bg-gradient-to-br from-indigo-800 to-orange-600 px-4 py-2 text-sm text-zinc-200 hover:from-indigo-900 hover:to-orange-800"
        onClick={() => onLogout(setAuth, router)}
      >
        Logout
      </button>
    </div>
  ) : (
    <button
      className="ml-6 cursor-pointer rounded-md bg-gradient-to-br from-indigo-800 to-orange-600 px-4 py-2 text-sm text-zinc-200 hover:from-indigo-900 hover:to-orange-800"
      onClick={() => onClickLoginButton(signIn)}
    >
      Google Login
    </button>
  );
}
