import type { providers } from 'ethers';
import Web3 from 'web3';
import { TransactionReceipt } from 'web3-core';

import { IWalletReq } from '../data-hooks/auth-api';
import { VERIFICATION_MESSAGE } from './constants/auth';
import { ETHEREUM_CHAINS } from './constants/ethereum';
import { NETWORK } from './constants/network';
import { Provider, ProviderValue } from './constants/user';
import { sleep } from './time';

export const loginEthereumWallet = async (): Promise<IWalletReq> => {
  const accounts = await window?.ethereum?.request({ method: 'eth_requestAccounts' });
  const publicKey = accounts ? Web3.utils.toChecksumAddress(accounts[0]) : '';
  const signature = await (window?.ethereum as providers.ExternalProvider)?.request?.({
    method: 'personal_sign',
    params: [VERIFICATION_MESSAGE, publicKey],
  });
  return { publicKey, signature, chain: 'ETHEREUM' };
};

/**
 * Since local tx will never work for Etherscan, we only really care about
 * checking if current chain is GOERLI, otherwise, we just use mainnet base URL
 */
export const getBaseEtherscanURL = () => {
  if (process.env.NEXT_PUBLIC_ETHEREUM_CHAIN === ETHEREUM_CHAINS.GOERLI) {
    return 'https://goerli.etherscan.io/tx/';
  } else {
    return 'https://etherscan.io/tx/';
  }
};

/* waits for transaction to be confirmed, then returns the receipt */
export async function getTransactionReceipt(transactionHash: string, web3: Web3): Promise<TransactionReceipt> {
  let receipt = null;
  while (receipt === null) {
    receipt = await web3.eth.getTransactionReceipt(transactionHash);
    if (receipt && !receipt.status) throw Error('Transaction failed');

    console.log('Waiting for transaction...');
    await sleep(4000);
  }

  return receipt;
}

// lots of confix indexing helpers
// TODO: make this a NetworkConfig type
export const getRPCEndpoint = (network: string) => {
  switch (network) {
    case NETWORK.BNB_MAINNET:
      return process.env.NEXT_PUBLIC_BNB_MAINNET_RPC_URL;
    case NETWORK.BNB_TESTNET:
      return process.env.NEXT_PUBLIC_BNB_TESTNET_RPC_URL;
    case NETWORK.ETHEREUM:
      return process.env.NEXT_PUBLIC_ETHEREUM_RPC_URL;
    case NETWORK.POLYGON:
      return process.env.NEXT_PUBLIC_POLYGON_RPC_URL;
    case NETWORK.MUMBAI:
      return process.env.NEXT_PUBLIC_MUMBAI_RPC_URL;
    case NETWORK.HARDHAT:
      return process.env.NEXT_PUBLIC_HARDHAT_RPC_URL;
    default:
      return process.env.NEXT_PUBLIC_GOERLI_RPC_URL;
  }
};

export const getFactoryAddress = (provider: string) => {
  switch (provider) {
    case Provider.AIRLOCK_ETH_V1:
      return process.env.NEXT_PUBLIC_ETHEREUM_FACTORY_V1_ADDRESS;
    case Provider.AIRLOCK_BNB_MAINNET_V0:
      return process.env.NEXT_PUBLIC_BNB_MAINNET_FACTORY_V0_ADDRESS;
    case Provider.AIRLOCK_BNB_TESTNET_V0:
      return process.env.NEXT_PUBLIC_BNB_TESTNET_FACTORY_V0_ADDRESS;
    case Provider.AIRLOCK_MUMBAI_V0:
      return process.env.NEXT_PUBLIC_MUMBAI_FACTORY_V0_ADDRESS;
    case Provider.AIRLOCK_POLYGON_V0:
      return process.env.NEXT_PUBLIC_POLYGON_FACTORY_V0_ADDRESS;
    default:
      return process.env.NEXT_PUBLIC_ETHEREUM_FACTORY_ADDRESS;
  }
};

export const getWeb3Provider = (network: string) => {
  const endpoint = getRPCEndpoint(network);
  return new Web3(new Web3.providers.HttpProvider(endpoint as string));
};

export const getNetworkFromProvider = (provider: ProviderValue) => {
  switch (provider) {
    case Provider.AIRLOCK_SOL:
      return NETWORK.SOLANA;
    case Provider.AIRLOCK_ETH:
      return NETWORK.ETHEREUM;
    case Provider.AIRLOCK_ETH_V1:
      return NETWORK.ETHEREUM;
    case Provider.AIRLOCK_GOERLI_V1:
      return NETWORK.GOERLI;
    case Provider.AIRLOCK_POLYGON_V0:
      return NETWORK.POLYGON;
    case Provider.AIRLOCK_MUMBAI_V0:
      return NETWORK.MUMBAI;
    case Provider.AIRLOCK_POLYGON_V1:
      return NETWORK.POLYGON;
    case Provider.AIRLOCK_MUMBAI_V1:
      return NETWORK.MUMBAI;
    case Provider.AIRLOCK_BNB_MAINNET_V0:
      return NETWORK.BNB_MAINNET;
    case Provider.AIRLOCK_BNB_TESTNET_V0:
      return NETWORK.BNB_TESTNET;
    default:
      return NETWORK.ETHEREUM;
  }
};

export const isProviderEVM = (provider: ProviderValue) => {
  return (
    provider === Provider.AIRLOCK_ETH_V1 ||
    provider === Provider.AIRLOCK_POLYGON_V0 ||
    provider === Provider.AIRLOCK_MUMBAI_V0 ||
    provider === Provider.AIRLOCK_POLYGON_V1 ||
    provider === Provider.AIRLOCK_MUMBAI_V1 ||
    provider === Provider.AIRLOCK_GOERLI_V1 ||
    provider === Provider.AIRLOCK_BNB_MAINNET_V0 ||
    provider === Provider.AIRLOCK_BNB_TESTNET_V0
  );
};
