import * as Sentry from '@sentry/browser';
import * as splToken from '@solana/spl-token';
import { MessageSignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, sendAndConfirmRawTransaction } from '@solana/web3.js';

import { IWalletReq } from '../data-hooks/auth-api';
import { CHAIN_TYPE, VERIFICATION_MESSAGE } from './constants/auth';

export const loginSolanaWallet = async (
  publicKey: string,
  signMessage?: MessageSignerWalletAdapterProps['signMessage'],
): Promise<IWalletReq | null> => {
  try {
    const messageBytes = new TextEncoder().encode(VERIFICATION_MESSAGE);
    let signature: Uint8Array | undefined;

    try {
      if (signMessage) {
        const signedMessage = await signMessage(messageBytes);
        signature = signedMessage;
      }
    } catch (err) {
      console.log(err);
    }

    return { publicKey, signature, chain: CHAIN_TYPE.SOLANA };
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    return null;
  }
};

export const getTokenMint = async (tokenAddress: string) => {
  const connection = new Connection(process.env.NEXT_PUBLIC_SOLANA_RPC_URL as string, 'confirmed');
  const tokenMint = await splToken.getMint(connection, new PublicKey(tokenAddress));
  return tokenMint;
};

export const signAndSendTransactions = async (
  connection: Connection,
  wallet: AnchorWallet,
  transactions: Transaction[],
) => {
  const signedTransactions = await wallet.signAllTransactions(transactions);

  const transactionHashResults = await Promise.allSettled(
    signedTransactions.map((signedTransaction: Transaction) =>
      sendAndConfirmRawTransaction(connection, signedTransaction.serialize(), {
        commitment: 'confirmed',
        maxRetries: 5,
      }),
    ),
  );

  const errors = transactionHashResults.map((result: PromiseSettledResult<string>) =>
    result.status !== 'fulfilled' ? result?.reason : null,
  );
  const transactionHashes = transactionHashResults.map((result) =>
    result.status === 'fulfilled' ? result?.value : null,
  );

  return { transactionHashes, errors };
};
