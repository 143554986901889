import type { Mongoose } from 'mongoose';

/**
 * Global is used here to maintain a cached connection across hot reloads
 * in development. This prevents connections growing exponentially
 * during API Route usage.
 */
interface Connection {
  conn: Mongoose | null;
  promise: Promise<Mongoose> | null;
}

declare global {
  var mongoose: Connection; // eslint-disable-line no-var
}
let cached = global.mongoose;
if (!cached) {
  cached = global.mongoose = { conn: null, promise: null };
}

export async function initMongoConnection() {
  // Ignore connection when running tests as jest already mocks mongodb
  if (process.env.NEXT_TEST_ENVIRONMENT) return;

  const MONGODB_URI = process.env.MONGODB_URI;

  if (cached.conn) {
    return cached.conn;
  }

  if (!cached.promise && typeof window === 'undefined') {
    const mongoose = await import('mongoose');
    cached.promise = mongoose.connect(MONGODB_URI!);
  }

  cached.conn = await cached.promise;
  return cached.conn;
}
