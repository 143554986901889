import { ConnectButton } from '@rainbow-me/rainbowkit';
import { WalletMultiButton as SolanaMultiButton } from '@solana/wallet-adapter-react-ui';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { useWallet } from '@/lib/onChain';

import { CHAIN_TYPE } from '../lib/constants/auth';
import GoogleAuthButton from './GoogleAuthButton';

const SolanaWalletButton = () => {
  const { connected } = useWallet();

  useEffect(() => {
    const jwt = Cookies.get('AUTH_JWT');
    if (!connected && jwt) {
      // MAG-557: connected is always false first, then becomes true
      //          This ends up requesting wallet sign at every page refresh
      // logoutWallet();
    }
  }, [connected]);

  return <SolanaMultiButton />;
};

export default function WalletSelectButton({ chain }) {
  return (
    <>
      {chain === CHAIN_TYPE.SOLANA && <SolanaWalletButton />}
      {chain === CHAIN_TYPE.ETHEREUM && <ConnectButton />}
      {chain === CHAIN_TYPE.OTHER && <GoogleAuthButton />}
    </>
  );
}
