import { IUserDBSchema } from '@/lib/interfaces/user';

import { callAPI } from '../lib/callAPI';

interface IUserReq {
  userId: string;
}

interface IUpdateUserReq {
  userId: string;
  update: IUserDBSchema;
}

export class UserAPI {
  private authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  async getCurrentUserData(): Promise<IUserDBSchema> {
    const response = await callAPI(this.authToken, 'GET', '/api/user/current');
    return response?.data.user;
  }

  async getUsersList(): Promise<IUserDBSchema[]> {
    const response = await callAPI(this.authToken, 'GET', '/api/user');
    return response?.data.users;
  }

  async createUser(user: IUserDBSchema): Promise<IUserDBSchema | undefined> {
    try {
      const response = await callAPI(this.authToken, 'POST', '/api/user', { user });
      return response?.data.user;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }

  async getUser({ userId }: IUserReq): Promise<IUserDBSchema> {
    const url = `/api/user/${userId}`;
    const response = await callAPI(this.authToken, 'GET', url);
    return response?.data.user;
  }

  async updateUser({ userId, update }: IUpdateUserReq) {
    const url = `/api/user/${userId}`;
    const response = await callAPI(this.authToken, 'POST', url, { update });
    return response?.data;
  }

  async deleteUser({ userId }: IUserReq) {
    const url = `/api/user/${userId}`;
    const response = await callAPI(this.authToken, 'DELETE', url);
    return response?.data.user;
  }

  async getUserAddresses() {
    const response = await callAPI(this.authToken, 'GET', '/api/user/addresses');
    return response?.data.addresses;
  }
}
