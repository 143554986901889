import { DistributionsCategoryValue } from '@/lib/constants/distribution';
import { DISTRIBUTIONS_PAGINATE_PAGE_SIZE } from '@/lib/constants/distribution';

import { fetchAndSerializeDistributions, serializeDistributions } from '../db/serializers/distribution';
import { callAPI } from '../lib/callAPI';
import { ICreateDistributionReq, IDistributionDBSchema } from '../lib/interfaces/distribution';

interface IGetDistributionReq {
  distributionId: string;
}

interface IGetDistributionsListReq {
  skip?: number;
  stakeholderId?: string | null;
  limit?: number;
  category?: DistributionsCategoryValue;
}

interface IUpdateDistributionReq {
  distributionId: string;
  update: Partial<IDistributionDBSchema>;
}

interface IDeleteDistributionReq {
  distributionId: string;
}

interface IDistributionListResponse {
  distributions: IDistributionDBSchema[];
  count: number;
}

export class DistributionAPI {
  private authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  static async getDistribution({ distributionId }: IGetDistributionReq) {
    const url = `/api/distribution/claim/${distributionId}`;
    const response = await callAPI('', 'GET', url);
    return response?.data.row;
  }

  async getDistributionsList(args?: IGetDistributionsListReq): Promise<IDistributionDBSchema[]> {
    const url = '/api/distribution';
    const response = await callAPI(this.authToken, 'GET', url);
    return response?.data.distributions;
  }

  async getDistributionsListByCategory(args?: IGetDistributionsListReq): Promise<IDistributionListResponse> {
    let url = `/api/distribution`;
    if (args) {
      const { category = '', skip = 0, stakeholderId = '', limit = DISTRIBUTIONS_PAGINATE_PAGE_SIZE } = args;
      url = `${url}?category=${category}&limit=${limit}&skip=${skip}&stakeholderId=${stakeholderId}`;
    }
    const response = await callAPI(this.authToken, 'GET', url);
    return response?.data;
  }

  async createDistribution(args: ICreateDistributionReq) {
    const { distribution, stakeholder, token } = args;
    const url = `/api/distribution/`;
    const response = await callAPI(this.authToken, 'POST', url, {
      distribution,
      stakeholder,
      token,
    });
    return response?.data.distribution;
  }

  async updateDistribution({ distributionId, update }: IUpdateDistributionReq) {
    const url = `/api/distribution/${distributionId}`;
    const response = await callAPI(this.authToken, 'POST', url, { update });
    return response?.data;
  }

  async deleteDistribution({ distributionId }: IDeleteDistributionReq) {
    const url = `/api/distribution/${distributionId}`;
    const response = await callAPI(this.authToken, 'DELETE', url);
    return response?.data?.message;
  }

  static async getActiveDistributions(stakeholderAddress: string) {
    const url = `/api/distribution/claim`;
    const response = await callAPI('', 'POST', url, { stakeholderAddress });
    const serializedDistributions = await fetchAndSerializeDistributions(response?.data.distributions);
    return serializedDistributions;
  }

  async getDistributionsStats() {
    const url = `/api/distribution/statistics`;
    const response = await callAPI(this.authToken, 'GET', url);
    return response?.data;
  }

  static async updateWithdrawnAmount(distribution: any) {
    const url = `api/distribution/claim/${distribution._id}`;
    const [serializedDistribution] = serializeDistributions([distribution]);
    const onChainObjectWithdrawnAmount = await serializedDistribution.getWithdrawnAmount();
    const response = await callAPI('', 'POST', url, {
      update: { withdrawnAmount: onChainObjectWithdrawnAmount },
    });
    return response?.data;
  }
}
