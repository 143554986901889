import { captureException } from '@sentry/browser';
import { MessageSignerWalletAdapterProps } from '@solana/wallet-adapter-base';
import Cookies from 'js-cookie';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { NextApiHandler, NextApiRequest, NextApiResponse } from 'next';

import { AuthAPI, IGetJWTReq, SMSStatus } from '../data-hooks/auth-api';
import { ModalPromiseGenerator } from '../data-hooks/modal-provider';
import { initMongoConnection } from '../db/mongo';
import { CHAIN_TYPE } from './constants/auth';
import { loginEthereumWallet } from './ethereum';
import { loginGoogleAuth } from './google';
import { loginSolanaWallet } from './solana';

export const logoutWallet = async () => {
  Cookies.remove('AUTH_JWT');
  Cookies.remove('GAUTH');
  Cookies.remove('GMAIL');
  Cookies.remove('GNAME');
};

interface getAuthTokenClientParams {
  publicKey: string | null;
  signMessage?: MessageSignerWalletAdapterProps['signMessage'];
  createSMSModal?: null | ModalPromiseGenerator;
  chain?: string;
}
export const getAuthTokenClient = async ({
  publicKey,
  signMessage,
  createSMSModal = null,
  chain = CHAIN_TYPE.SOLANA,
}: getAuthTokenClientParams): Promise<string | null> => {
  const authAPI = new AuthAPI();

  const jwt = Cookies.get('AUTH_JWT');
  if (jwt && (await authAPI.verifyJWT(jwt))) {
    return jwt;
  }

  let request: IGetJWTReq | null = null;
  if (chain === CHAIN_TYPE.SOLANA) {
    request = await loginSolanaWallet(publicKey!, signMessage);
  } else if (chain === CHAIN_TYPE.ETHEREUM) {
    request = await loginEthereumWallet();
  } else if (chain === CHAIN_TYPE.OTHER) {
    request = loginGoogleAuth(Cookies.get('GMAIL'), Cookies.get('GAUTH'));
  }
  if (request === null) return null;

  try {
    let status: SMSStatus | undefined;
    let first = true;
    let response = null;
    // To allow the user to attempt multiple Twilio codes, we wrap this logic in a do...while loop.
    do {
      response = await authAPI.getJWT(request!);
      // If we don't have a response/response.status, retry.
      if (!response?.status) break;

      status = response.status;
      // Unless the status is 'rejected', we want the initial status sent to the SMSModal to be undefined.
      // Otherwise, the SMSModal will show a retry error.
      const modalSignal: SMSStatus | undefined = response.status === 'rejected' || !first ? status : undefined;
      const code: string | null = createSMSModal && (await createSMSModal(modalSignal));
      first = false;

      request = { chain, publicKey: publicKey ?? '', code: code ?? '' };
    } while (createSMSModal && (status === 'pending' || status === 'rejected'));
    if (!response) return null;
    const { jwt } = response;
    if (!jwt) return null;
    Cookies.set('AUTH_JWT', jwt, { expires: 1 });
    return jwt;
  } catch (error) {
    captureException(error);
    console.error(error);
    return null;
  }
};

export type NextApiHandlerWithJWT<T = any> = (
  req: NextApiRequest,
  res: NextApiResponse<T>,
  decoded: JwtPayload,
) => void | Promise<void>;

// A wrapper to initialize mongoose connection.
// Defined generically to support wrapping both API routes and getServerSideProps.
export const withMongooseConnection = <T extends Array<any>, U>(handler: (...args: T) => Promise<U> | U) => {
  return async (...args: T): Promise<U> => {
    try {
      await initMongoConnection();
      return handler(...args);
    } catch (err: any) {
      throw new Error('Mongoose connection not initialized: ', err);
    }
  };
};

// server-side utility to verify token and return decoded wallet publicKey
export const withJWT = (handler: NextApiHandlerWithJWT): NextApiHandler => {
  return async (req, res) => {
    try {
      const decoded = jwt.verify(req.headers.authorization ?? '', process.env.SECRET_KEY ?? '');
      if (typeof decoded === 'string') throw new Error('Incorrect JWT format');
      return handler(req, res, decoded);
    } catch (err) {
      console.error(err);
      return res.status(403).json({ message: 'JWT verification failed in auth.' });
    }
  };
};
