import { Values } from '../types';

export const LOG_TYPE = {
  DISTRIBUTION: 'DISTRIBUTION',
  STAKEHOLDER: 'STAKEHOLDER',
  TOKEN: 'TOKEN',
  USER: 'USER',
  TRANSACTION: 'TRANSACTION',
  INVITE: 'INVITE',
} as const;
export type LogTypeValue = Values<typeof LOG_TYPE>;

export const ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;
export type ActionValue = Values<typeof ACTION>;

export const TRANSACTION_TYPE = {
  BULK_CANCEL_STREAM: 'BULK_CANCEL_STREAM',
  BULK_CREATE_STREAM: 'BULK CREATE STREAM',
  CREATE_STREAM: 'CREATE STREAM',
  START_STREAM: 'START STREAM',
  CANCEL_STREAM: 'CANCEL STREAM',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  WITHDRAW_STREAM: 'WITHDRAW_STREAM',
} as const;
export type TransactionTypeValue = Values<typeof TRANSACTION_TYPE>;

export const STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;
export type StatusValue = Values<typeof STATUS>;
