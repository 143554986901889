import Head from 'next/head';

import {
  GENERIC_PAGE_ERROR_TEXT,
  LOGIN_FAILURE_TEXT,
  WAITING_ONCHAIN_DATA_TEXT,
  WAITING_WALLET_CONFIRMATION_TEXT,
  WALLET_ERROR_HINT_TEXT,
} from '../lib/constants/ui';
import { BaseLayout } from './Layout';

interface IAuthLoadingProps {
  chain: string;
  error?: boolean;
}

export const AuthLoading = ({ chain, error }: IAuthLoadingProps) => (
  <BaseLayout chain={chain}>
    <Head>
      <title>Magna Admin</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <div className="flex items-baseline justify-between gap-x-10">
      <h1 className="text-gray-300">{error ? LOGIN_FAILURE_TEXT : WAITING_WALLET_CONFIRMATION_TEXT}</h1>
      {!error && (
        <div className="flex items-baseline">
          <div className="pr-2 italic leading-none text-gray-300">Click Here</div>
          <svg width="86" height="26" viewBox="30 0 56 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              className="stroke-gray-300"
              d="M-160.5 25.5H59.5C70.5457 25.5 79.5 16.5457 79.5 5.5V1M79.5 1L75 8M79.5 1L85 8"
            />
          </svg>
        </div>
      )}
    </div>
    <br />
    <h3 className="text-xs italic text-gray-500">{WALLET_ERROR_HINT_TEXT}</h3>
  </BaseLayout>
);

export const SuspenseLoading = ({ chain, error }: IAuthLoadingProps) => (
  <BaseLayout chain={chain}>
    <Head>
      <title>Magna Admin</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <h1 className="text-gray-300">{error ? GENERIC_PAGE_ERROR_TEXT : WAITING_ONCHAIN_DATA_TEXT}</h1>
  </BaseLayout>
);
