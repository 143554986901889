import { IStakeholderDBSchema } from '@/lib/interfaces/stakeholder';

import { callAPI } from '../lib/callAPI';

interface IUpdateStakeholderReq {
  stakeholderId: string;
  update: IStakeholderDBSchema;
}
export class StakeholderAPI {
  private authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  async getStakeholders(): Promise<IStakeholderDBSchema[]> {
    const response = await callAPI(this.authToken, 'GET', '/api/stakeholder');
    return response?.data.stakeholders;
  }

  async createStakeholder(stakeholder: IStakeholderDBSchema): Promise<IStakeholderDBSchema> {
    const response = await callAPI(this.authToken, 'POST', '/api/stakeholder', { stakeholder });
    return response?.data.stakeholder;
  }

  async updateStakeholder({ stakeholderId, update }: IUpdateStakeholderReq) {
    const url = `/api/stakeholder/${stakeholderId}`;
    const response = await callAPI(this.authToken, 'POST', url, { update });
    return response?.data.stakeholder;
  }
}
