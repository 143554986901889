/**
 * This function is used for local ethereum development on a hardhat chain.
 * Because the local chain doesn't auto mine blocks, we use this function
 * to manually increase the block number so that time has passed therefore
 * we have tokens vested to be claimed.
 * @param addSeconds number of seconds to add to current time
 * @param web3 web3 object
 */
export default function increaseTime(addSeconds: any, web3: any) {
  const id = Date.now();

  return new Promise((resolve, reject) => {
    web3.currentProvider.send(
      {
        jsonrpc: '2.0',
        method: 'evm_increaseTime',
        params: [addSeconds],
        id,
      },
      (err1: any) => {
        if (err1) return reject(err1);

        web3.currentProvider.send(
          {
            jsonrpc: '2.0',
            method: 'evm_mine',
            id: id + 1,
          },
          (err2: any, res: unknown) => (err2 ? reject(err2) : resolve(res)),
        );
      },
    );
  });
}
