import { BigNumber } from 'ethers';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import BENTO_ABI from '../public/BentoBox.json';
import FURO_ABI from '../public/FuroVesting.json';
import addresses from '../public/contracts/addresses.json';
import { CREATE_VESTING_EVENT, ETHEREUM_CHAINS, NULL_ADDRESS } from './constants/ethereum';
import increaseTime from './dev/ethereum';
import { getTransactionReceipt } from './ethereum';

function getWeb3Objects(): { web3: Web3; furoContract: any; bentoContract: any } {
  try {
    const web3 = new Web3(new Web3.providers.HttpProvider(process.env.NEXT_PUBLIC_ETHEREUM_RPC_URL!));
    const furoContract = new web3.eth.Contract(FURO_ABI as AbiItem[], addresses.FURO);
    const bentoContract = new web3.eth.Contract(BENTO_ABI as AbiItem[], addresses.BENTOBOX);

    return { web3, furoContract, bentoContract };
  } catch (error: any) {
    throw Error(error.message);
  }
}

export const createVesting = async (distribution: any) => {
  try {
    const { web3, furoContract } = getWeb3Objects();

    const startTime = BigNumber.from(Math.floor(Number(distribution.startTime)));

    const lastPiece = distribution.pieces[distribution.pieces.length - 1];
    const firstPiece = distribution.pieces[0];

    let steps = '';
    let cliffDuration = '';
    let stepPercentageRaw = '';
    switch (distribution.pieces.length) {
      case 1:
        steps = Math.ceil(Number(firstPiece.numberOfPeriods)).toString();
        cliffDuration = '0';
        stepPercentageRaw = (1 / Number(steps)).toString();
        break;
      default:
        steps = Math.ceil(Number(lastPiece.numberOfPeriods)).toString();
        cliffDuration = firstPiece.periodLength.toString();
        stepPercentageRaw = (1 / distribution.allocation).toString();
    }

    const stepPercentage = web3.utils.toWei(stepPercentageRaw.substring(0, 17), 'ether');
    const amount = web3.utils.toWei(distribution.allocation.toString(), 'ether');
    const stepDuration = lastPiece.periodLength.toString();

    await signBentoContract(distribution.owner.address);

    const nonce = await web3.eth.getTransactionCount(distribution.owner.address, 'latest');

    const transactionParameters = {
      from: distribution.owner.address,
      to: addresses.FURO,
      nonce: nonce.toString(16),
      data: furoContract.methods
        .createVesting({
          token: distribution.token.address,
          recipient: distribution.stakeholder.address,
          start: startTime,
          cliffDuration: cliffDuration,
          stepDuration: stepDuration,
          steps: steps,
          stepPercentage: stepPercentage,
          amount: amount,
          fromBentoBox: false,
        })
        .encodeABI(),
    };

    // TODO: figure out a better type-safe way to do this
    const transactionHash = await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    if (process.env.NEXT_PUBLIC_ETHEREUM_CHAIN === ETHEREUM_CHAINS.LOCAL) {
      const ONE_YEAR = 60 * 60 * 24 * 365;
      await increaseTime(ONE_YEAR, web3);
    }

    const receipt = await getTransactionReceipt(transactionHash, web3);

    if (receipt) {
      // topics - Array: An array with the index parameter topics of the log,
      // without the topic[0] if its a non-anonymous event, otherwise with topic[0].
      const topics = [
        receipt.logs[receipt.logs.length - 1].topics[1],
        receipt.logs[receipt.logs.length - 1].topics[2],
        receipt.logs[receipt.logs.length - 1].topics[3],
        receipt.logs[receipt.logs.length - 1].topics[0],
      ];
      const decodedLog = web3.eth.abi.decodeLog(CREATE_VESTING_EVENT, receipt.logs[4].data, topics);

      return { transactionHash, onChainId: decodedLog.vestId };
    }
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const cancelVesting = async (vestId: any, account: string) => {
  try {
    const { web3, furoContract } = getWeb3Objects();
    const nonce = await web3.eth.getTransactionCount(account, 'latest');

    const transactionParameters = {
      from: account,
      to: addresses.FURO,
      nonce: nonce.toString(16),
      data: furoContract.methods.stopVesting(vestId).encodeABI(),
    };

    // TODO: figure out a better type-safe way to do this
    const transactionHash = await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    return { transactionHash };
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const withdrawVesting = async (vestId: any, recipient: string) => {
  try {
    const { web3, furoContract } = getWeb3Objects();
    const nonce = await web3.eth.getTransactionCount(recipient, 'latest');

    const transactionParameters = {
      from: recipient,
      to: addresses.FURO,
      nonce: nonce.toString(16),
      data: furoContract.methods.withdraw(vestId, '0x', false).encodeABI(),
    };

    // TODO: figure out a better type-safe way to do this
    const transactionHash = await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    return { transactionHash };
  } catch (error: any) {
    throw Error(error.message);
  }
};

// TODO: will use this function so we have real values for claimable tokens, not estimates maybe?
export const balanceVesting = async (vestId: any, account: string) => {
  try {
    const { furoContract } = getWeb3Objects();

    const balance = await furoContract.methods.vestBalance(vestId).call({ from: account });

    return { balance };
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const signBentoContract = async (account: string) => {
  try {
    const { web3, bentoContract } = getWeb3Objects();
    const nonce = await web3.eth.getTransactionCount(account, 'latest');

    const transactionParameters = {
      from: account,
      to: addresses.BENTOBOX,
      nonce: nonce.toString(16),
      data: bentoContract.methods
        .setMasterContractApproval(account, addresses.FURO, true, '0', NULL_ADDRESS, NULL_ADDRESS)
        .encodeABI(),
    };

    // TODO: figure out a better type-safe way to do this
    await (window.ethereum as any)?.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const getOne = async (distribution: any, account: string) => {
  try {
    const { furoContract } = getWeb3Objects();

    const vest = await furoContract.methods.vests(distribution.pieces[0].onChainId).call({ from: account });

    return vest;
  } catch (err: any) {
    return null;
  }
};
