/*
    Serializer for centralizing usage, improving testability, and adding human-readable abstractions.
*/
import sum from 'lodash/sum';

import * as airlockEth from '../../lib/airlockEth';
import * as airlockEthV1 from '../../lib/airlockEthV1';
import * as airlockSol from '../../lib/airlockSol';
import {
  IUnlockCadenceOption,
  SECONDS_PER_MONTH,
  STATUS,
  UnlockCadenceOptions,
} from '../../lib/constants/distribution';
import { WEI_PER_ETH } from '../../lib/constants/ethereum';
import { Provider } from '../../lib/constants/user';
import * as furoVest from '../../lib/furoVest';
import { getTokenMint } from '../../lib/solana';
import { formatNumber, getUTCDateString } from '../../lib/ui';
import { getZebecStreamInfo } from '../../lib/zebec';
import { IOnChainDistributionV1, IOnChainPieceV1, IOnChainTokenV1 } from '@/lib/v1/utils';

const milliseconds = 1000;

export interface Piece {
  startTime: number;
  periodLength: number;
  numberOfPeriods: number;
  allocationPercent: number;
}

export class Distribution {
  public distribution: any;
  public withdrawnAmount?: number;
  public status?: string;
  public percentVested?: number;

  constructor(distributionDBEntry: any) {
    this.distribution = distributionDBEntry;
  }

  async fetch() {
    this.withdrawnAmount = await this.getWithdrawnAmount();
    const { status, percentVested } = await this.getStreamProgress();
    this.status = status;
    this.percentVested = percentVested;
  }

  static async fetch(distributionDBEntry: any) {
    const d = new Distribution(distributionDBEntry);
    d.fetch();
    return d;
  }

  static getTotalDistributionAllocation(distributions: any[], tokenId: string) {
    return distributions
      .filter((distribution) => distribution.token._id === tokenId)
      .map((distribution) => distribution.allocation)
      .reduce((sum, allocation) => sum + allocation, 0);
  }

  static getCurrentDistributions(distributions: any[]) {
    return distributions.filter(
      (distribution: any) => distribution.status === STATUS.IN_PROGRESS || distribution.status === STATUS.SCHEDULED,
    );
  }

  static isLockup(piece: Piece) {
    return piece.numberOfPeriods === 1;
  }

  static isTransfer(piece: Piece) {
    return piece.numberOfPeriods === 1 && piece.periodLength === 1;
  }

  static getPieceEndTime(piece: Piece) {
    return piece.startTime + piece.numberOfPeriods * piece.periodLength;
  }

  static getEndTime(distribution: any) {
    return distribution.pieces
      .map((piece: any) => piece.startTime + piece.periodLength * piece.numberOfPeriods)
      .reduce((x: number, y: number) => Math.max(x, y), 0);
  }

  static getPieceUnlockPercent(piece: Piece) {
    const currentTime = Date.now() / 1000;

    if (currentTime < piece.startTime) {
      return 0.0;
    } else if (currentTime > Distribution.getPieceEndTime(piece)) {
      return 1.0;
    } else {
      return Math.floor((currentTime - piece.startTime) / piece.periodLength) / piece.numberOfPeriods;
    }
  }

  static getUnlockPercent(distribution: any) {
    return distribution.pieces.reduce(
      (acc: number, piece: Piece) => Distribution.getPieceUnlockPercent(piece) * piece.allocationPercent + acc,
      0.0,
    );
  }

  static getStreamProgress(distribution: any, onChainObject: any) {
    const status = STATUS.NOT_STARTED;
    const percentVested = 0.0;

    if (distribution.completedWithoutMagna) {
      return { status: STATUS.COMPLETED, percentVested: 1.0 };
    } else if (distribution.cancelled) {
      return { status: STATUS.CANCELLED, percentVested: 0.0 };
    } else if (!onChainObject) {
      return { status: STATUS.NOT_STARTED, percentVested: 0.0 };
    }

    // could also use: await getSolanaClockTimestamp()
    const startTime = distribution.startTime;
    const currentTime = Math.floor(Date.now() / 1000);
    const endTime = Distribution.getEndTime(distribution);

    if (currentTime < startTime) {
      return {
        status: STATUS.SCHEDULED,
        percentVested: 0.0,
      };
    } else if (currentTime > endTime) {
      return {
        status: STATUS.COMPLETED,
        percentVested: 1.0,
      };
    } else if (currentTime > startTime && currentTime < endTime) {
      return {
        status: STATUS.IN_PROGRESS,
        percentVested: Distribution.getUnlockPercent(distribution),
      };
    }

    return { status, percentVested };
  }

  async getStreamProgress(): Promise<{
    status: string;
    percentVested: number;
  }> {
    try {
      const onChainObject = await this.getOnChainObject();

      if (
        this.distribution.provider === Provider.FURO &&
        process.env.NEXT_PUBLIC_ETHEREUM_RPC_URL === 'http://localhost:8545'
      ) {
        return this.getStreamProgressDev();
      }

      return Distribution.getStreamProgress(this.distribution, onChainObject);
    } catch (err) {
      console.log(err);
      return { status: STATUS.NOT_STARTED, percentVested: 0.0 };
    }
  }

  async getOnChainObject() {
    let object;
    if (this.distribution.provider === Provider.AIRLOCK_SOL) {
      object = await airlockSol.getOne(this.distribution);
    } else if (this.distribution.provider === Provider.AIRLOCK_ETH) {
      object = await airlockEth.getOne(this.distribution);
    } else if (
      this.distribution.provider === Provider.AIRLOCK_BNB_TESTNET_V0 ||
      this.distribution.provider === Provider.AIRLOCK_BNB_MAINNET_V0
    ) {
      object = await airlockEth.getOne(this.distribution);
    } else if (
      this.distribution.provider === Provider.AIRLOCK_MUMBAI_V0 ||
      this.distribution.provider === Provider.AIRLOCK_POLYGON_V0
    ) {
      object = await airlockEth.getOne(this.distribution);
    } else if (this.distribution.provider === Provider.FURO) {
      object = await furoVest.getOne(this.distribution, this.distribution.owner.address);
    } else if (this.distribution.provider === Provider.AIRLOCK_ETH_V1) {
      const onChainObjects = await airlockEthV1.getMany([this.distribution]);
      return onChainObjects[0];
    }

    return object;
  }

  getFirstPiece() {
    return this.distribution.pieces[0];
  }

  getLastPiece() {
    return this.distribution.pieces[this.distribution.pieces.length - 1];
  }

  getAllocation() {
    return this.distribution.allocation;
  }

  getStakeholderId() {
    return this.distribution.stakeholder._id;
  }

  getNumberOfUnlocks(): string {
    return formatNumber(sum(this.distribution.pieces.map((piece: Piece) => piece.numberOfPeriods)));
  }

  getUnlockCadence(): IUnlockCadenceOption {
    return UnlockCadenceOptions.filter((o) => this.getLastPiece().periodLength == o.value)[0];
  }

  getUnlockRateText() {
    const pieceAllocation = this.distribution.allocation * this.getLastPiece().allocationPercent;
    const amountPerPeriod = pieceAllocation / this.getLastPiece().numberOfPeriods;
    const tokenSymbol = this.distribution.token.symbol || 'N/A';
    const unlockCadence = this.getUnlockCadence().label;

    if (Distribution.isTransfer(this.getLastPiece())) {
      `${formatNumber(amountPerPeriod)} ${tokenSymbol} one time transfer`;
    }
    return `${formatNumber(amountPerPeriod)} ${tokenSymbol} ${unlockCadence}`;
  }

  getScheduleLengthInMs() {
    return (
      this.distribution.pieces.reduce(
        (acc: number, piece: any) => acc + piece.periodLength * piece.numberOfPeriods,
        0,
      ) * milliseconds
    );
  }

  getUnlockScheduleDates() {
    const startTimeInMS = this.distribution.startTime * milliseconds;

    let text = `${getUTCDateString(new Date(startTimeInMS))} (schedule start)`;

    if (Distribution.isLockup(this.distribution.pieces[0]) && !Distribution.isTransfer(this.distribution.pieces[0])) {
      const periodLengthInMS = this.getFirstPiece().periodLength * milliseconds;
      text += `\n ${getUTCDateString(new Date(startTimeInMS + periodLengthInMS))} (lockup end)`;
    }

    const scheduleLengthInMS = this.getScheduleLengthInMs();
    text += `\n ${getUTCDateString(new Date(startTimeInMS + scheduleLengthInMS))} (unlock finish)`;

    return text;
  }

  getLockupDetails(piece: Piece) {
    const lockupAmount = piece.allocationPercent * this.distribution.allocation;
    const lockupLength = piece.periodLength / SECONDS_PER_MONTH;

    return { lockupAmount, lockupLength };
  }

  getLinearUnlockDetails(piece: Piece) {
    const linearUnlockAmount = piece.allocationPercent * this.distribution.allocation;
    const linearUnlockLength = (piece.periodLength * piece.numberOfPeriods) / SECONDS_PER_MONTH;

    return { linearUnlockAmount, linearUnlockLength };
  }

  getUnlockScheduleText() {
    return this.distribution.pieces
      .map((piece: Piece) => {
        if (Distribution.isTransfer(piece)) {
          return `${formatNumber(piece.allocationPercent * this.distribution.allocation)} ${
            this.distribution.token.symbol || 'N/A'
          } instant transfer \n`;
        } else if (Distribution.isLockup(piece)) {
          const { lockupAmount, lockupLength } = this.getLockupDetails(piece);
          return `${formatNumber(lockupAmount)} ${this.distribution.token.symbol || 'N/A'} with ${formatNumber(
            lockupLength,
          )} month lockup \n`;
        } else {
          const { linearUnlockAmount, linearUnlockLength } = this.getLinearUnlockDetails(piece);
          return `${formatNumber(linearUnlockAmount)} ${this.distribution.token.symbol || 'N/A'} over ${formatNumber(
            linearUnlockLength,
          )} months`;
        }
      })
      .join('\n');
  }

  getScheduleLength() {
    return this.distribution.pieces
      .map((piece: any) => piece.periodLength * piece.numberOfPeriods)
      .reduce((x: number, y: number) => x + y, 0);
  }

  /**
   * Furo distributions can not be started if startTime < block.timestamp...
   * Therefore, we consider a valid start time as any time in the future.
   */
  hasValidStartTime() {
    const currentTime = Math.floor(Date.now() / 1000);
    return this.distribution.startTime > currentTime;
  }

  /**
   * This function is used for local ethereum development on a hardhat chain.
   * Because we are manually setting the chain forward in time we need to
   * "spoof" the progress of the distribution, therefore, we mark the distribution as completed.
   */
  getStreamProgressDev() {
    const validStart = this.hasValidStartTime();
    if (validStart && this.distribution.pieces[0].onChainId) {
      return {
        status: STATUS.COMPLETED,
        percentVested: 1.0,
      };
    } else {
      return {
        status: STATUS.NOT_STARTED,
        percentVested: 0.0,
      };
    }
  }

  async getWithdrawnAmount(): Promise<number> {
    const onChainObject = await this.getOnChainObject();
    if (!onChainObject) {
      return 0.0;
    }

    if (this.distribution.provider === Provider.ZEBEC) {
      const lastPiece = this.getLastPiece();
      const progress = await getZebecStreamInfo(lastPiece.onChainId);
      return ('withdrawnAmount' in progress && progress.withdrawnAmount) || -1;
    } else if (this.distribution.provider === Provider.AIRLOCK_SOL) {
      const tokenMint = await getTokenMint(this.distribution.token.address);
      return onChainObject.withdrawn.toNumber() / 10 ** tokenMint.decimals;
    } else if (this.distribution.provider === Provider.AIRLOCK_ETH) {
      return onChainObject.withdrawn / WEI_PER_ETH;
    } else if (
      this.distribution.provider === Provider.AIRLOCK_MUMBAI_V0 ||
      this.distribution.provider === Provider.AIRLOCK_POLYGON_V0
    ) {
      return onChainObject.withdrawn / WEI_PER_ETH;
    } else if (this.distribution.provider === Provider.FURO) {
      return onChainObject.claimed / WEI_PER_ETH;
    } else if (this.distribution.provider === Provider.AIRLOCK_ETH_V1) {
      return parseInt(onChainObject.withdrawn) / WEI_PER_ETH;
    }

    return 0.0;
  }

  getV1Pieces(): IOnChainPieceV1[] {
    return this.distribution.pieces.map((piece: any) => ({
      startTime: piece.startTime,
      periodLength: piece.periodLength,
      numberOfPeriods: piece.numberOfPeriods,
      amount: piece.allocationPercent * this.distribution.allocation,
    }));
  }

  getV1Token(): IOnChainTokenV1 {
    const { symbol, address } = this.distribution.token;
    return { symbol, address };
  }

  getV1Distribution(): IOnChainDistributionV1 {
    // check if withdrawnAmount or percentVested is non-nullish, otherwise panic
    if (this.withdrawnAmount == null || this.percentVested == null)
      throw new Error('withdrawnAmount or percentVested is nullish');

    // TODO: the number in funded is almost certainly wrong, fix it.
    return {
      pieces: this.getV1Pieces(),
      token: this.getV1Token(),
      claimed: this.withdrawnAmount!,
      vested: this.percentVested! * this.distribution.allocation,
      funded: this.distribution.fundedAmount ?? this.distribution.allocation,
      id: this.distribution._id,
      // TODO: this is BAD, it is NOT NULL
      user: null,
    };
  }
}

export type ISerializedDistribution = Distribution;

export const serializeDistributions = (distributions: any[]) => {
  return distributions.map((d) => new Distribution(d));
};

export const fetchAndSerializeDistributions = (distributions: any[]) => {
  return Promise.all(distributions.map((d) => Distribution.fetch(d)));
};
